import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
} from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  const history = useHistory()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      history.push("/dashboard")
    }
  }, [])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    if (!loading) {
      props.loginUser(values, props.history)
      setLoading(true)
    }
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  useEffect(() => {
    console.log(props.error)
    if (loading) {
      setLoading(false)
    }
  }, [props.error])

  return (
    <React.Fragment>
      <MetaTags>
        <title>G5</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to G5.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error.error === "string" ? (
                        <Alert color="danger">{props.error.error}</Alert>
                      ) : null}

                      <div>
                        <AvField
                          name="email"
                          label="Email"
                          // value="admin@themesbrand.com"
                          className="form-control mb-3"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div>
                        <AvField
                          name="password"
                          label="Password"
                          // value="123456"
                          type="password"
                          className="form-control mb-3"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}
                      <div>
                        <p className="mb-0">
                          By signing in, you agree to our{" "}
                          <Link href="#" className="text-primary">
                            Terms & Conditions
                          </Link>
                          {" and "}
                          <Link href="#" className="text-primary">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {loading && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          {" " + "Sign In"}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-success text-white border-success"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-microsoft" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                            <TwitterLogin
                              loginUrl={
                                "http://localhost:4000/api/v1/auth/twitter"
                              }
                              onSuccess={this.twitterResponse}
                              onFailure={this.onFailure}
                              requestTokenUrl={
                                "http://localhost:4000/api/v1/auth/twitter/revers"
                              }
                              showIcon={false}
                              tag={"div"}
                            >
                              <a
                                href=""
                                className="social-list-item bg-info text-white border-info"
                              >
                                <i className="mdi mdi-twitter" />
                              </a>
                            </TwitterLogin>
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        {/* <Link to="#" className="text-muted"> */}
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forget password
                        </Link>
                      </div>

                      {/* <div>
                        <Link to="/new_application" className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block waves-effect waves-light">
                            New Application
                          </button>
                        </Link>
                      </div> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="registration" className="fw-medium text-primary">
                    {" "}
                    Request access{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} G5. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { registerUser, loginUser, apiError, socialLogin })(
    Login
  )
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
