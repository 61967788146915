import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"
import images from "assets/images"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const ForgetPasswordModal = props => {
  const [forgetPasswordError, setforgetpassword_error] = useState(null)
  const [loading, setLoading] = useState(false)

  function handleValidSubmit(event, values) {
    setforgetpassword_error(null)
    console.log(values)
    setLoading(true)
    sendOTP(values)
    // login(values)
    // props.loginUser(values, props.history)
  }

  useEffect(() => {
    setLoading(false)
  }, [props.isOpen])

  const sendOTP = async values => {
    let formdata = new FormData()
    formdata.append("email", values.email)
    formdata.append("action", "forgot_password")

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_REQUESTOTP, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    setLoading(false)
    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.setItem("hfOTP", postresponse.data.otp)
        localStorage.setItem("hfForm", JSON.stringify(values))

        props.onEmailSend()
      } else {
        setforgetpassword_error(postresponse.message)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div
          className="modal-header"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="btn btn-link text-primary px-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="bx bx-chevron-left font-size-16 align-middle"></i>
            <span aria-hidden="true" className="font-weight-bold font-size-14">
              {" Back to Hapivity"}
            </span>
          </button>
        </div>
        <Row className="modal-body" style={{ backgroundColor: "#FBFBFB" }}>
          <Col>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <Row className="justify-content-center">
                <Col md={6} lg={4} className="align-self-center">
                  <Row className="justify-content-center mb-4">
                    <Col xs="auto">
                      <img
                        src={asset.iconLock}
                        srcSet={`${asset.iconLock} 300w, ${asset.iconLockx2} 768w, ${asset.iconLockx3} 1280w`}
                        alt=""
                        style={{ maxWidth: "72px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                      <span className="font-weight-extrabold font-size-20">
                        {"Reset your password"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto" className="text-center">
                      <span className="font-weight-semibold font-size-14">
                        {
                          "Enter the email address associated with your Hapivity account."
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconEmail}
                            srcSet={`${asset.iconEmail} 300w, ${asset.iconEmailx2} 768w, ${asset.iconEmailx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="email"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="Email"
                            type="email"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="email"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="Email"
                      type="email"
                      // required
                      validate={{
                        email: {
                          value: true,
                          errorMessage: "Please enter a valid email",
                        },
                        required: {
                          value: true,
                          errorMessage: "Please enter email",
                        },
                      }}
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col className="w-100">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        type="submit"
                        disabled={loading}
                        // onClick={props.onEmailSend}
                      >
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
                        )}
                        {"Continue"}
                      </button>
                    </Col>
                  </Row>
                  {forgetPasswordError && (
                    <Row className="mb-3">
                      <Col>
                        <Alert
                          color="danger"
                          role="alert"
                          className="border-radius-8 font-size-14 font-weight-semibold align-middle"
                        >
                          {forgetPasswordError}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

ForgetPasswordModal.propTypes = {
  setting: PropTypes.object,
}

export default ForgetPasswordModal
