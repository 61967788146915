import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_LOGIN,
  SHOW_JOIN_NOW,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_FORGET_PASSWORD,
  SHOW_RESET_PASSWORD,
  SHOW_SIGNUP_VERIFICATION,
  SHOW_REFERRAL,
  SHOW_ONBOARD,
} from "./actionTypes"

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
})

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})

export const showRightSidebarAction = isopen => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})

export const showLoginAction = isopen => ({
  type: SHOW_LOGIN,
  payload: isopen,
})

export const showJoinNowAction = isopen => ({
  type: SHOW_JOIN_NOW,
  payload: isopen,
})

export const showForgetPasswordAction = isopen => ({
  type: SHOW_FORGET_PASSWORD,
  payload: isopen,
})

export const showResetPasswordAction = isopen => ({
  type: SHOW_RESET_PASSWORD,
  payload: isopen,
})

export const showSignupVerificationAction = isopen => ({
  type: SHOW_SIGNUP_VERIFICATION,
  payload: isopen,
})

export const showReferralAction = isopen => ({
  type: SHOW_REFERRAL,
  payload: isopen,
})

export const showOnboardAction = isopen => ({
  type: SHOW_ONBOARD,
  payload: isopen,
})

export const showSidebar = isopen => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = isopen => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})
