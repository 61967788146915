import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

import image360 from "assets/images/rainfora/balcony_view_angle.jpg"

// import b1Balcony from "assets/images/rainfora/interior/b1/Balcony_B1.webp"
// import b1Bathroom1 from "assets/images/rainfora/interior/b1/Bathroom_1_B1.webp"
// import b1Bedroom1 from "assets/images/rainfora/interior/b1/Bedroom_1_B1.webp"
// import b1Foyer from "assets/images/rainfora/interior/b1/Foyer_B1.webp"
// import b1Kitchen from "assets/images/rainfora/interior/b1/Kitchen_B1.webp"
// import b1Living from "assets/images/rainfora/interior/b1/Living_Dining_B1.webp"
// import b1MasterBathroom from "assets/images/rainfora/interior/b1/Master_Bath_B1.webp"
// import b1MasterBedroom from "assets/images/rainfora/interior/b1/Master_Bedroom_B1.webp"

// import gBalcony from "assets/images/rainfora/interior/g/Balcony_G.webp"
// import gBathroom1 from "assets/images/rainfora/interior/g/Bathroom_1_G.webp"
// import gBedroom1 from "assets/images/rainfora/interior/g/Bedroom_1_G.webp"
// import gBedroom2 from "assets/images/rainfora/interior/g/Bedroom_2_G.webp"
// import gBedroom3 from "assets/images/rainfora/interior/g/Bedroom_3_G.webp"
// import gKitchen from "assets/images/rainfora/interior/g/Kitchen_G.webp"
// import gKitchenDining from "assets/images/rainfora/interior/g/Kitchen_Dining_G.webp"
// import gLiving from "assets/images/rainfora/interior/g/Living_G.webp"
// import gMasterBathroom from "assets/images/rainfora/interior/g/Master_Bathroom_G.webp"
// import gMasterBedroom from "assets/images/rainfora/interior/g/Master_Bedroom_G.webp"
// import gYard from "assets/images/rainfora/interior/g/Yard_G.webp"

import a1Foyer from "assets/images/sensoria/interior/a1/A1_Foyer.webp"
import a1Kitchen from "assets/images/sensoria/interior/a1/A2_Kitchen.webp"
import a1LivingDining from "assets/images/sensoria/interior/a1/A3_LivingDining.webp"
import a1Balcony from "assets/images/sensoria/interior/a1/A4_Balcony.webp"
import a1Bedroom1 from "assets/images/sensoria/interior/a1/A5_Bedroom1.webp"
import a1Bathroom1 from "assets/images/sensoria/interior/a1/A6_Bath1.webp"
import a1Bedroom2 from "assets/images/sensoria/interior/a1/A7_Bedroom2.webp"
import a1Bathroom2 from "assets/images/sensoria/interior/a1/A8_Bath2.webp"
import a1Bedroom3 from "assets/images/sensoria/interior/a1/A9_Bedroom3.webp"

import b2Foyer from "assets/images/sensoria/interior/b2/B1_Foyer.webp"
import b2Kitchen from "assets/images/sensoria/interior/b2/B2_Kitchen.webp"
import b2LivingDining from "assets/images/sensoria/interior/b2/B3_LivingDining.webp"
import b2Balcony from "assets/images/sensoria/interior/b2/B4_Balcony.webp"
import b2Bedroom1 from "assets/images/sensoria/interior/b2/B5_Bedroom1.webp"
import b2Bathroom1 from "assets/images/sensoria/interior/b2/B6_Bath1.webp"
import b2Bedroom2 from "assets/images/sensoria/interior/b2/B7_Bedroom2.webp"
import b2Bathroom2 from "assets/images/sensoria/interior/b2/B8_Bath2.webp"
import b2Bedroom3 from "assets/images/sensoria/interior/b2/B9_Bedroom3.webp"
import b2Bedroom4 from "assets/images/sensoria/interior/b2/B10_Bedroom4.webp"

import c1mFoyer from "assets/images/sensoria/interior/c1m/C1_Foyer.webp"
import c1mKitchen from "assets/images/sensoria/interior/c1m/C2_Kitchen.webp"
import c1mLivingDining from "assets/images/sensoria/interior/c1m/C3_LivingDining.webp"
import c1mBalcony from "assets/images/sensoria/interior/c1m/C4_Balcony.webp"
import c1mCorridor from "assets/images/sensoria/interior/c1m/C7_Corridor.webp"
import c1mBedroom1 from "assets/images/sensoria/interior/c1m/C5_Bedroom1.webp"
import c1mBathroom1 from "assets/images/sensoria/interior/c1m/C6_Bath1.webp"
import c1mBedroom2 from "assets/images/sensoria/interior/c1m/C10_Bedroom2.webp"
import c1mBathroom2 from "assets/images/sensoria/interior/c1m/C11_Bath2.webp"
import c1mBedroom3 from "assets/images/sensoria/interior/c1m/C8_Bedroom3.webp"
import c1mBathroom3 from "assets/images/sensoria/interior/c1m/C9_Bath3.webp"
import c1mBedroom4 from "assets/images/sensoria/interior/c1m/C12_Bedroom4.webp"
import c1mBathroom4 from "assets/images/sensoria/interior/c1m/C13_Bath4.webp"
import c1mUtility from "assets/images/sensoria/interior/c1m/C14_Utility.webp"

import markersPin from "assets/images/rainfora/interior/interior_pin.png"

// import b1Plan from "assets/images/rainfora/unit/floor_plan/b1.png"
// import gPlan from "assets/images/rainfora/unit/floor_plan/g-reflect.png"

import a1Plan from "assets/images/sensoria/unit/floor_plan/A1.png"
import b2Plan from "assets/images/sensoria/unit/floor_plan/B2.png"
import c1mPlan from "assets/images/sensoria/unit/floor_plan/C1(m).png"

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin"
import "@photo-sphere-viewer/markers-plugin/index.css"
import { CompassPlugin } from "@photo-sphere-viewer/compass-plugin"
import "@photo-sphere-viewer/compass-plugin/index.css"

const interiorImages = {
  a1Foyer: {
    image: a1Foyer,
    name: "Foyer",
    unit: "A1",
    position: { textureX: 2000, textureY: 1000 },
    compass: { top: 190, right: 140 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Kitchen",
        position: { textureX: 2800, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
      {
        id: "a1LivingDining",
        position: { textureX: 2000, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  a1Kitchen: {
    image: a1Kitchen,
    name: "Kitchen",
    unit: "A1",
    position: { textureX: 2000, textureY: 1000 },
    compass: { top: 200, right: 90 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Foyer",
        position: { textureX: 1000, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "a1LivingDining",
        position: { textureX: 1700, textureY: 1225 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  a1LivingDining: {
    image: a1LivingDining,
    name: "Living & Dining",
    unit: "A1",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 120, right: 140 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Foyer",
        position: { textureX: 60, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "a1Kitchen",
        position: { textureX: 3750, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
      {
        id: "a1Bedroom1",
        position: { textureX: 1215, textureY: 1375 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
      {
        id: "a1Bedroom3",
        position: { textureX: 2825, textureY: 1125 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
      {
        id: "a1Balcony",
        position: { textureX: 1980, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Balcony",
      },
      {
        id: "a1Bathroom2",
        position: { textureX: 2925, textureY: 1065 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 2",
      },
    ],
  },
  a1Balcony: {
    image: a1Balcony,
    name: "Balcony",
    unit: "A1",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 20, right: 150 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Bedroom1",
        position: { textureX: 300, textureY: 1175 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
      {
        id: "a1LivingDining",
        position: { textureX: 40, textureY: 1175 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  a1Bedroom1: {
    image: a1Bedroom1,
    name: "Bedroom 1",
    unit: "A1",
    position: { textureX: 1250, textureY: 1000 },
    compass: { top: 100, right: 180 },
    plan: a1Plan,
    markers: [
      {
        id: "a1LivingDining",
        position: { textureX: 3375, textureY: 1415 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
      {
        id: "a1Bathroom1",
        position: { textureX: 225, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 1",
      },
    ],
  },
  a1Bathroom1: {
    image: a1Bathroom1,
    name: "Bathroom 1",
    unit: "A1",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 130, right: 190 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Bedroom1",
        position: { textureX: 2275, textureY: 1475 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
    ],
  },
  a1Bedroom2: {
    image: a1Bedroom2,
    name: "Bedroom 2",
    unit: "A1",
    position: { textureX: 3500, textureY: 1000 },
    compass: { top: 130, right: 20 },
    plan: a1Plan,
    markers: [
      {
        id: "a1LivingDining",
        position: { textureX: 1525, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
      {
        id: "a1Bathroom2",
        position: { textureX: 2600, textureY: 1400 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 2",
      },
      {
        id: "a1Bedroom3",
        position: { textureX: 1900, textureY: 1275 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
    ],
  },
  a1Bathroom2: {
    image: a1Bathroom2,
    name: "Bathroom 2",
    unit: "A1",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 110, right: 10 },
    plan: a1Plan,
    markers: [
      {
        id: "a1LivingDining",
        position: { textureX: 1015, textureY: 1225 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
      {
        id: "a1Bedroom2",
        position: { textureX: 3975, textureY: 1515 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "a1Bedroom3",
        position: { textureX: 1425, textureY: 1375 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
    ],
  },
  a1Bedroom3: {
    image: a1Bedroom3,
    name: "Bedroom 3",
    unit: "A1",
    position: { textureX: 1500, textureY: 1000 },
    compass: { top: 70, right: 40 },
    plan: a1Plan,
    markers: [
      {
        id: "a1Bedroom2",
        position: { textureX: 3875, textureY: 1275 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "a1Bathroom2",
        position: { textureX: 3600, textureY: 1325 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 2",
      },
    ],
  },
  b2Foyer: {
    image: b2Foyer,
    name: "Foyer",
    unit: "B2",
    position: { textureX: 1500, textureY: 1000 },
    compass: { top: 180, right: 60 },
    plan: b2Plan,
    markers: [
      {
        id: "b2LivingDining",
        position: { textureX: 1900, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
      {
        id: "b2Kitchen",
        position: { textureX: 1000, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
      {
        id: "b2Bedroom1",
        position: { textureX: 2475, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
    ],
  },
  b2Kitchen: {
    image: b2Kitchen,
    name: "Kitchen",
    unit: "B2",
    position: { textureX: 2000, textureY: 1000 },
    compass: { top: 170, right: 100 },
    plan: b2Plan,
    markers: [
      {
        id: "b2LivingDining",
        position: { textureX: 2150, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
      {
        id: "b2Foyer",
        position: { textureX: 3300, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "b2Bedroom1",
        position: { textureX: 2900, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
    ],
  },
  b2LivingDining: {
    image: b2LivingDining,
    name: "Living & Dining",
    unit: "B2",
    position: { textureX: 2000, textureY: 1000 },
    compass: { top: 110, right: 90 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Foyer",
        position: { textureX: 3850, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "b2Kitchen",
        position: { textureX: 150, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
      {
        id: "b2Balcony",
        position: { textureX: 2150, textureY: 1175 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Balcony",
      },
      {
        id: "b2Bedroom2",
        position: { textureX: 975, textureY: 1085 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "b2Bedroom3",
        position: { textureX: 900, textureY: 1150 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
      {
        id: "b2Bedroom4",
        position: { textureX: 1075, textureY: 1150 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 4",
      },
    ],
  },
  b2Balcony: {
    image: b2Balcony,
    name: "Balcony",
    unit: "B2",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 30, right: 80 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Foyer",
        position: { textureX: 3875, textureY: 1100 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "b2LivingDining",
        position: { textureX: 50, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  b2Bedroom1: {
    image: b2Bedroom1,
    name: "Bedroom 1",
    unit: "B2",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 160, right: 20 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Foyer",
        position: { textureX: 1030, textureY: 1470 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "b2Bathroom1",
        position: { textureX: 3725, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 1",
      },
    ],
  },
  b2Bathroom1: {
    image: b2Bathroom1,
    name: "Bathroom 1",
    unit: "B2",
    position: { textureX: 3000, textureY: 1000 },
    compass: { top: 180, right: 0 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Bedroom1",
        position: { textureX: 1225, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
    ],
  },
  b2Bedroom2: {
    image: b2Bedroom2,
    name: "Bedroom 2",
    unit: "B2",
    position: { textureX: 1000, textureY: 1000 },
    compass: { top: 100, right: 200 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Bathroom2",
        position: { textureX: 350, textureY: 1375 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 2",
      },
      {
        id: "b2Bedroom3",
        position: { textureX: 3400, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
      {
        id: "b2LivingDining",
        position: { textureX: 3150, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  b2Bathroom2: {
    image: b2Bathroom2,
    name: "Bathroom 2",
    unit: "B2",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 130, right: 210 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Bedroom2",
        position: { textureX: 2050, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
    ],
  },
  b2Bedroom3: {
    image: b2Bedroom3,
    name: "Bedroom 3",
    unit: "B2",
    position: { textureX: 500, textureY: 1000 },
    compass: { top: 130, right: 150 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Bedroom2",
        position: { textureX: 1550, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "b2Bedroom4",
        position: { textureX: 1900, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 4",
      },
      {
        id: "b2LivingDining",
        position: { textureX: 2650, textureY: 1275 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  b2Bedroom4: {
    image: b2Bedroom4,
    name: "Bedroom 4",
    unit: "B2",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 90, right: 150 },
    plan: b2Plan,
    markers: [
      {
        id: "b2Bedroom2",
        position: { textureX: 325, textureY: 1400 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "b2Bedroom3",
        position: { textureX: 3900, textureY: 1400 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
    ],
  },
  c1mFoyer: {
    image: c1mFoyer,
    name: "Foyer",
    unit: "C1(M)",
    position: { textureX: 1500, textureY: 1000 },
    compass: { top: 185, right: 55 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mKitchen",
        position: { textureX: 950, textureY: 1500 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
      {
        id: "c1mBedroom1",
        position: { textureX: 2300, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
      {
        id: "c1mLivingDining",
        position: { textureX: 1950, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  c1mKitchen: {
    image: c1mKitchen,
    name: "Kitchen",
    unit: "C1(M)",
    position: { textureX: 1500, textureY: 1000 },
    compass: { top: 190, right: 80 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mFoyer",
        position: { textureX: 2800, textureY: 1385 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "c1mBedroom1",
        position: { textureX: 2475, textureY: 1175 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
      {
        id: "c1mLivingDining",
        position: { textureX: 2075, textureY: 1175 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  c1mLivingDining: {
    image: c1mLivingDining,
    name: "Living & Dining",
    unit: "C1(M)",
    position: { textureX: 2000, textureY: 1000 },
    compass: { top: 120, right: 70 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mFoyer",
        position: { textureX: 3900, textureY: 1275 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Foyer",
      },
      {
        id: "c1mBedroom1",
        position: { textureX: 3350, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
      {
        id: "c1mBalcony",
        position: { textureX: 2150, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Balcony",
      },
      {
        id: "c1mCorridor",
        position: { textureX: 1025, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
      {
        id: "c1mKitchen",
        position: { textureX: 150, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Kitchen",
      },
    ],
  },
  c1mBalcony: {
    image: c1mBalcony,
    name: "Balcony",
    unit: "C1(M)",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 60, right: 80 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mCorridor",
        position: { textureX: 340, textureY: 1125 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
      {
        id: "c1mLivingDining",
        position: { textureX: 30, textureY: 1260 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  c1mCorridor: {
    image: c1mCorridor,
    name: "Corridor",
    unit: "C1(M)",
    position: { textureX: 1000, textureY: 1000 },
    compass: { top: 120, right: 140 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBathroom4",
        position: { textureX: 650, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 4",
      },
      {
        id: "c1mBedroom3",
        position: { textureX: 1000, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
      {
        id: "c1mBedroom2",
        position: { textureX: 1225, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
      {
        id: "c1mBedroom4",
        position: { textureX: 2500, textureY: 1475 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 4",
      },
      {
        id: "c1mUtility",
        position: { textureX: 3850, textureY: 1400 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Utility",
      },
      {
        id: "c1mLivingDining",
        position: { textureX: 3000, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  c1mBedroom1: {
    image: c1mBedroom1,
    name: "Bedroom 1",
    unit: "C1(M)",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 140, right: 20 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBathroom1",
        position: { textureX: 3950, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 1",
      },
      {
        id: "c1mLivingDining",
        position: { textureX: 1190, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Living & Dining",
      },
    ],
  },
  c1mBathroom1: {
    image: c1mBathroom1,
    name: "Bathroom 1",
    unit: "C1(M)",
    position: { textureX: 3500, textureY: 1000 },
    compass: { top: 190, right: 20 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBedroom1",
        position: { textureX: 2055, textureY: 1380 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 1",
      },
    ],
  },
  c1mBedroom2: {
    image: c1mBedroom2,
    name: "Bedroom 2",
    unit: "C1(M)",
    position: { textureX: 2500, textureY: 1000 },
    compass: { top: 95, right: 160 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBathroom2",
        position: { textureX: 945, textureY: 1500 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 2",
      },
      {
        id: "c1mCorridor",
        position: { textureX: 3450, textureY: 1275 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
    ],
  },
  c1mBathroom2: {
    image: c1mBathroom2,
    name: "Bathroom 2",
    unit: "C1(M)",
    position: { textureX: 1500, textureY: 1000 },
    compass: { top: 90, right: 210 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBedroom2",
        position: { textureX: 2900, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
    ],
  },
  c1mBedroom3: {
    image: c1mBedroom3,
    name: "Bedroom 3",
    unit: "C1(M)",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 120, right: 190 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mCorridor",
        position: { textureX: 2975, textureY: 1200 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
      {
        id: "c1mBathroom3",
        position: { textureX: 3200, textureY: 1425 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bathroom 3",
      },
      {
        id: "c1mBedroom2",
        position: { textureX: 2775, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
    ],
  },
  c1mBathroom3: {
    image: c1mBathroom3,
    name: "Bathroom 3",
    unit: "C1(M)",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 140, right: 175 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mBedroom3",
        position: { textureX: 1600, textureY: 1450 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 3",
      },
      {
        id: "c1mBedroom2",
        position: { textureX: 2250, textureY: 1350 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
    ],
  },
  c1mBedroom4: {
    image: c1mBedroom4,
    name: "Bedroom 4",
    unit: "C1(M)",
    position: { textureX: 1000, textureY: 1000 },
    compass: { top: 100, right: 115 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mCorridor",
        position: { textureX: 50, textureY: 1250 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
    ],
  },
  c1mBathroom4: {
    image: c1mBathroom4,
    name: "Bathroom 4",
    unit: "C1(M)",
    position: { textureX: 0, textureY: 1000 },
    compass: { top: 140, right: 140 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mCorridor",
        position: { textureX: 2200, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
      {
        id: "c1mBedroom2",
        position: { textureX: 1750, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Bedroom 2",
      },
    ],
  },
  c1mUtility: {
    image: c1mUtility,
    name: "Utility",
    unit: "C1(M)",
    position: { textureX: 3500, textureY: 1000 },
    compass: { top: 145, right: 120 },
    plan: c1mPlan,
    markers: [
      {
        id: "c1mCorridor",
        position: { textureX: 1750, textureY: 1300 },
        image: markersPin,
        size: { width: 36, height: 36 },
        tooltip: "Corridor",
      },
    ],
  },
  // b1_balcony: {
  //   image: b1Balcony,
  //   name: "Balcony",
  //   unit: "B1",
  //   position: { textureX: 0, textureY: 1000 },
  //   compass: { top: 20, right: 50 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_living",
  //       position: { textureX: 3850, textureY: 1200 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //   ],
  // },
  // b1_bathroom_1: {
  //   image: b1Bathroom1,
  //   name: "Bathroom 1",
  //   unit: "B1",
  //   position: { textureX: 0, textureY: 1000 },
  //   compass: { top: 120, right: 150 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_living",
  //       position: { textureX: 2900, textureY: 1000 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //     {
  //       id: "b1_master_bedroom",
  //       position: { textureX: 2672, textureY: 1150 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //     {
  //       id: "b1_bedroom_1",
  //       position: { textureX: 3350, textureY: 1150 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //   ],
  // },
  // b1_bedroom_1: {
  //   image: b1Bedroom1,
  //   name: "Bedroom 1",
  //   unit: "B1",
  //   position: { textureX: 500, textureY: 1000 },
  //   compass: { top: 170, right: 130 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_master_bedroom",
  //       position: { textureX: 2150, textureY: 1300 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //     {
  //       id: "b1_living",
  //       position: { textureX: 2400, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //   ],
  // },
  // b1_foyer: {
  //   image: b1Foyer,
  //   name: "Foyer",
  //   unit: "B1",
  //   position: { textureX: 1500, textureY: 1000 },
  //   compass: { top: 240, right: 50 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_kitchen",
  //       position: { textureX: 1000, textureY: 1400 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "b1_living",
  //       position: { textureX: 2050, textureY: 1200 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //   ],
  // },
  // b1_kitchen: {
  //   image: b1Kitchen,
  //   name: "Kitchen",
  //   unit: "B1",
  //   position: { textureX: 2500, textureY: 1000 },
  //   compass: { top: 240, right: 110 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_living",
  //       position: { textureX: 2250, textureY: 1100 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //     {
  //       id: "b1_foyer",
  //       position: { textureX: 3000, textureY: 1500 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Foyer",
  //     },
  //   ],
  // },
  // b1_living: {
  //   image: b1Living,
  //   name: "Living & Dining",
  //   unit: "B1",
  //   position: { textureX: 1500, textureY: 1000 },
  //   compass: { top: 120, right: 40 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_foyer",
  //       position: { textureX: 0, textureY: 1150 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Foyer",
  //     },
  //     {
  //       id: "b1_kitchen",
  //       position: { textureX: 250, textureY: 1000 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "b1_bedroom_1",
  //       position: { textureX: 850, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //     {
  //       id: "b1_bathroom_1",
  //       position: { textureX: 980, textureY: 1230 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bathroom 1",
  //     },
  //     {
  //       id: "b1_master_bedroom",
  //       position: { textureX: 1150, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //     {
  //       id: "b1_balcony",
  //       position: { textureX: 1850, textureY: 1200 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Balcony",
  //     },
  //   ],
  // },
  // b1_master_bathroom: {
  //   image: b1MasterBathroom,
  //   name: "Master Bathroom",
  //   unit: "B1",
  //   position: { textureX: 500, textureY: 1000 },
  //   compass: { top: 80, right: 150 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_master_bedroom",
  //       position: { textureX: 3000, textureY: 1600 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //   ],
  // },
  // b1_master_bedroom: {
  //   image: b1MasterBedroom,
  //   name: "Master Bedroom",
  //   unit: "B1",
  //   position: { textureX: 1250, textureY: 1000 },
  //   compass: { top: 50, right: 130 },
  //   plan: b1Plan,
  //   markers: [
  //     {
  //       id: "b1_bedroom_1",
  //       position: { textureX: 3900, textureY: 1200 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //     {
  //       id: "b1_living",
  //       position: { textureX: 3750, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living & Dining",
  //     },
  //     {
  //       id: "b1_master_bathroom",
  //       position: { textureX: 4000, textureY: 1550 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bathroom",
  //     },
  //   ],
  // },
  // g_balcony: {
  //   image: gBalcony,
  //   name: "Balcony",
  //   unit: "G",
  //   position: { textureX: 0, textureY: 1000 },
  //   compass: { top: 30, right: 180 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_living",
  //       position: { textureX: 4000, textureY: 1350 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living",
  //     },
  //     {
  //       id: "g_kitchen_dining",
  //       position: { textureX: 100, textureY: 1150 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen & Dining",
  //     },
  //   ],
  // },
  // g_bathroom_1: {
  //   image: gBathroom1,
  //   name: "Bathroom 1",
  //   unit: "G",
  //   position: { textureX: 500, textureY: 1000 },
  //   compass: { top: 150, right: 80 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 1550, textureY: 1450 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "g_bedroom_1",
  //       position: { textureX: 2475, textureY: 1325 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //   ],
  // },
  // g_bedroom_1: {
  //   image: gBedroom1,
  //   name: "Bedroom 1",
  //   unit: "G",
  //   position: { textureX: 1500, textureY: 1000 },
  //   compass: { top: 100, right: 50 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 100, textureY: 1450 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "g_bedroom_2",
  //       position: { textureX: 75, textureY: 1325 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 2",
  //     },
  //     {
  //       id: "g_master_bedroom",
  //       position: { textureX: 3750, textureY: 1375 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //   ],
  // },
  // g_bedroom_2: {
  //   image: gBedroom2,
  //   name: "Bedroom 2",
  //   unit: "G",
  //   position: { textureX: 0, textureY: 1000 },
  //   compass: { top: 150, right: 60 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 1850, textureY: 1500 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "g_bedroom_1",
  //       position: { textureX: 2000, textureY: 1300 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //   ],
  // },
  // g_bedroom_3: {
  //   image: gBedroom3,
  //   name: "Bedroom 3",
  //   unit: "G",
  //   position: { textureX: 3000, textureY: 1000 },
  //   compass: { top: 150, right: 150 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen_dining",
  //       position: { textureX: 1100, textureY: 1500 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen & Dining",
  //     },
  //   ],
  // },
  // g_kitchen: {
  //   image: gKitchen,
  //   name: "Kitchen",
  //   unit: "G",
  //   position: { textureX: 500, textureY: 1000 },
  //   compass: { top: 130, right: 100 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen_dining",
  //       position: { textureX: 800, textureY: 1100 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen & Dining",
  //     },
  //     {
  //       id: "g_yard",
  //       position: { textureX: 3950, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Yard",
  //     },
  //     {
  //       id: "g_living",
  //       position: { textureX: 1300, textureY: 1300 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living",
  //     },
  //     {
  //       id: "g_balcony",
  //       position: { textureX: 1600, textureY: 1050 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Balcony",
  //     },
  //     {
  //       id: "g_bedroom_1",
  //       position: { textureX: 2830, textureY: 1225 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 1",
  //     },
  //     {
  //       id: "g_bedroom_2",
  //       position: { textureX: 3050, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 2",
  //     },
  //     {
  //       id: "g_bathroom_1",
  //       position: { textureX: 3100, textureY: 1300 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bathroom 1",
  //     },
  //     {
  //       id: "g_master_bedroom",
  //       position: { textureX: 2950, textureY: 1175 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //   ],
  // },
  // g_kitchen_dining: {
  //   image: gKitchenDining,
  //   name: "Kitchen & Dining",
  //   unit: "G",
  //   position: { textureX: 2500, textureY: 1000 },
  //   compass: { top: 160, right: 200 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 2825, textureY: 1100 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "g_living",
  //       position: { textureX: 2250, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Living",
  //     },
  //     {
  //       id: "g_balcony",
  //       position: { textureX: 2050, textureY: 1050 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Balcony",
  //     },
  //     {
  //       id: "g_bedroom_3",
  //       position: { textureX: 3775, textureY: 1375 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 3",
  //     },
  //   ],
  // },
  // g_living: {
  //   image: gLiving,
  //   name: "Living",
  //   unit: "G",
  //   position: { textureX: 1500, textureY: 1000 },
  //   compass: { top: 70, right: 180 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen_dining",
  //       position: { textureX: 3800, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen & Dining",
  //     },
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 2950, textureY: 1275 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //     {
  //       id: "g_yard",
  //       position: { textureX: 3225, textureY: 1000 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Yard",
  //     },
  //     {
  //       id: "g_balcony",
  //       position: { textureX: 1150, textureY: 1275 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Balcony",
  //     },
  //     {
  //       id: "g_bedroom_2",
  //       position: { textureX: 2750, textureY: 1000 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 2",
  //     },
  //     {
  //       id: "g_bathroom_1",
  //       position: { textureX: 2810, textureY: 1150 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bathroom 1",
  //     },
  //   ],
  // },
  // g_master_bathroom: {
  //   image: gMasterBathroom,
  //   name: "Master Bathroom",
  //   unit: "G",
  //   position: { textureX: 3500, textureY: 1000 },
  //   compass: { top: 130, right: 0 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_master_bedroom",
  //       position: { textureX: 2000, textureY: 1500 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bedroom",
  //     },
  //   ],
  // },
  // g_master_bedroom: {
  //   image: gMasterBedroom,
  //   name: "Master Bedroom",
  //   unit: "G",
  //   position: { textureX: 2000, textureY: 1000 },
  //   compass: { top: 90, right: 20 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_master_bathroom",
  //       position: { textureX: 3750, textureY: 1500 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Master Bathroom",
  //     },
  //     {
  //       id: "g_bedroom_2",
  //       position: { textureX: 600, textureY: 1250 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Bedroom 2",
  //     },
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 600, textureY: 1350 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //   ],
  // },
  // g_yard: {
  //   image: gYard,
  //   name: "Yard",
  //   unit: "G",
  //   position: { textureX: 3500, textureY: 1000 },
  //   compass: { top: 190, right: 120 },
  //   plan: gPlan,
  //   markers: [
  //     {
  //       id: "g_kitchen",
  //       position: { textureX: 2000, textureY: 1275 },
  //       image: markersPin,
  //       size: { width: 36, height: 36 },
  //       tooltip: "Kitchen",
  //     },
  //   ],
  // },
}

const plugins = [
  [
    MarkersPlugin,
    {
      markers: interiorImages["a1Foyer"].markers,
    },
  ],
  [
    CompassPlugin,
    {
      size: "90px",
      position: "bottom right",
      navigation: false,
      coneColor: "rgba(255, 0, 0, 0.2)",
    },
  ],
]

const allUnit = [
  // { unit: "B1", default: "b1_foyer", unit_display: "Type B1" },
  // { unit: "G", default: "g_kitchen_dining", unit_display: "Type G" },
  { unit: "A1", default: "a1Foyer", unit_display: "Type A1" },
  { unit: "B2", default: "b2Foyer", unit_display: "Type B2" },
  { unit: "C1(M)", default: "c1mFoyer", unit_display: "Type C1(M)" },
]

const InteriorTour = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.leftSideBarType == "default") setLeftSideBarSize(200)
    else setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const pageSheet = new CssManipulator("compassCss")
  const pageSheet2 = new CssManipulator("compassCss")

  const [compassTop, setCompassTop] = useState("290px !important")
  const [compassRight, setCompassRight] = useState("140px !important")
  const [currentPlan, setCurrentPlan] = useState(a1Plan)

  useEffect(() => {
    // setCompassTop("120px !important")
    // setCompassRight("40px !important")
    updateMarker('a1_foyer',true)
  }, [])

  useEffect(() => {
    pageSheet.change(".psv-compass--bottom-right", "right", compassRight)
    // console.log("right changed", compassRight)
  }, [compassRight])

  useEffect(() => {
    pageSheet2.change(".psv-compass--bottom-right", "top", compassTop)
    // console.log("top changed", compassTop)
  }, [compassTop])

  const pSRef = useRef(null)
  const [currentScene, setCurrentScene] = useState("Foyer")
  const [currentUnit, setCurrentUnit] = useState("A1")

  const updateMarker = (nextScene, isDefault = false) => {
    const markersPlugs = pSRef.current.getPlugin(MarkersPlugin)
    if (!markersPlugs) return
    markersPlugs.clearMarkers()
    setTimeout(() => {
      markersPlugs.setMarkers(interiorImages[nextScene].markers)
    }, 1500)
    pSRef.current.setPanorama(interiorImages[nextScene].image, {
      showLoader: false,
      position: interiorImages[nextScene].position,
      transition: "fade-only"
    })
    setCurrentScene(interiorImages[nextScene].name)
    setCurrentUnit(interiorImages[nextScene].unit)
    setCompassTop((interiorImages[nextScene].compass.top + 100) + "px !important")
    setCompassRight((interiorImages[nextScene].compass.right) + "px !important")
    setCurrentPlan(interiorImages[nextScene].plan)
  }

  const handleReady = instance => {
    const markersPlugs = instance.getPlugin(MarkersPlugin)
    if (!markersPlugs) return
    markersPlugs.addEventListener(
      "select-marker",
      ({ marker, doubleClick, rightClick }) => {
        // console.log(marker.id)
        updateMarker(marker.id)
      }
    ) 
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{ backgroundImage: "radial-gradient(#164A3D, #01221F)" }}
      >
        {/* <div className="container-fluid"> */}
        {/* {pageData &&
          pageData.map((object, index) => componentHandler(object, index))} */}
        {/* <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"> */}
        <Row className="p-0">
          <Col className="p-0">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <ReactPhotoSphereViewer
                  ref={pSRef}
                  src={interiorImages["a1Foyer"].image}
                  height={"100vh"}
                  width={"100%"}
                  defaultZoomLvl={0}
                  plugins={plugins}
                  onReady={handleReady}
                  navbar={false}
                  defaultYaw={0}
                  defaultPitch={0}
                ></ReactPhotoSphereViewer>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: 16,
            paddingLeft: 32,
            paddingRight: 32,
            // right: 32,
            backgroundImage: "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
          }}
        >
          <Col>
            <span className="text-primary font-size-56 font-weight-bold ">
              {"Type " + currentUnit + " " + currentScene}
            </span>
          </Col>
        </Row>

        <img
          src={currentPlan}
          style={{
            height: "350px",
            objectFit: "contain",
            top: 100,
            right: 0,
            position: "fixed",
          }}
        />

        <Card
          style={{
            backgroundColor: "#2F3D57CC",
            width: "148px",
            backdropFilter: "blur(20px)",
            borderRadius: "20px",
            position: "fixed",
            bottom: 0,
            left: 32 + leftSideBarSize,
          }}
          className="px-4 py-0 mb-3"
        >
          <Row>
            <Col>
              {allUnit.map((item, index) => (
                <Row className="my-2" key={"unit-" + index}>
                  <button
                    className={
                      currentUnit == item.unit
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      updateMarker(item.default, true)
                    }}
                  >
                    <span>{item.unit_display}</span>
                  </button>
                </Row>
              ))}
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(InteriorTour)
)

InteriorTour.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}

function CssManipulator(sheetTitle) {
  var that = this, // A reference to an instance
    len = document.styleSheets.length, // Caches the length of the collection
    n // General loop counter
  this.styleSheet = null // Stores the stylesheet for the instance
  this.selectors = {} // Stores the selectors we've handled
  this.cssRules = null // Caches cssRules of the given stylesheet
  // Search the given stylesheet by title and assign it and its cssRules to instance properties
  for (n = 0; n < len; n++) {
    if (document.styleSheets[n].title === sheetTitle) {
      this.styleSheet = document.styleSheets[n]
      this.cssRules =
        document.styleSheets[n].cssRules || document.styleSheets[n].rules
      break
    }
  }
  // Changes properties of the given selector
  this.change = function (selector, prop, value) {
    // FF knows only camel-cased propertynames, hence camel-casing the propName
    var propName =
      prop.charAt(0) === "-" ? prop.substring(1, prop.length) : prop
    propName = propName.replace(/-([a-z])/gi, function (str, chr) {
      return chr.toUpperCase()
    })
    if (selector in that.selectors) {
      // Change the rule, if we've handled this selector before
      that.styleSheet.cssRules[that.selectors[selector]].style[propName] = value
    } else {
      // Add a new rule if we haven't met this selector before
      that.selectors[selector] = that.styleSheet.insertRule(
        selector + "{" + prop + ":" + value + ";}",
        that.cssRules.length
      )
    }
    // console.log(selector)
    // console.log(that.selectors)
    // console.log(selector in that.selectors)
    // console.log(
    //   that.styleSheet.cssRules[that.selectors[selector]].style[propName]
    // )
  }
}
