import PropTypes from "prop-types"
import React from "react"
import { withRouter, Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"
import images from "assets/images"

import { connect } from "react-redux"
// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

import { loginUser } from "store/actions"

const LoginModal = props => {
  function handleValidSubmit(event, values) {
    login(values)
    props.loginUser(values, props.history)
  }

  const login = async values => {
    let formdata = new FormData()
    formdata.append("email", values.email)
    formdata.append("password", values.password)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_LOGIN, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.setItem("hAuth", postresponse.data.remember_token)
        localStorage.setItem("hId", postresponse.data.id)
        updateCurrency(postresponse.data.nationality)
        props.onLogin(postresponse.data.id, postresponse.data.remember_token)
        props.toggle()
      }
    }
  }

  const updateCurrency = country => {
    switch (country) {
      case "MY":
      case "my":
        localStorage.setItem("hCurrency", "MYR")
        break
      case "SG":
      case "sg":
        localStorage.setItem("hCurrency", "SGD")
        break
      case "AU":
      case "au":
        localStorage.setItem("hCurrency", "AUD")
        break
      case "ID":
      case "id":
        localStorage.setItem("hCurrency", "IDR")
        break
      case "GB":
      case "gb":
        localStorage.setItem("hCurrency", "GBP")
        break
      default:
        localStorage.setItem("hCurrency", "USD")
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div
          className="modal-header"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ backgroundColor: "#FBFBFB" }}>
          <Row className="justify-content-center">
            <Col md={6} lg={4}>
              <Row className="justify-content-center mb-4">
                <Col xs="auto">
                  <img
                    src={asset.logo}
                    alt=""
                    style={{ maxWidth: "170px", cursor: "pointer" }}
                    onClick={props.toggle}
                  />
                </Col>
              </Row>
              {/* <Row className="justify-content-center mb-3">
                <Col xs="auto">
                  <span className="font-weight-bold font-size-16">
                    {"Continue with"}
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="4" className="px-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-0 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <img
                        src={asset.googleLogin}
                        srcSet={`${asset.googleLogin} 300w, ${asset.googleLoginx2} 768w, ${asset.googleLoginx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          maxWidth: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col>
                <Col xs="4" className="px-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-0 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <img
                        src={asset.facebookLogin}
                        srcSet={`${asset.facebookLogin} 300w, ${asset.facebookLoginx2} 768w, ${asset.facebookLoginx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          maxWidth: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col>
                <Col xs="4" className="px-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-0 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <img
                        src={asset.appleLogin}
                        srcSet={`${asset.appleLogin} 300w, ${asset.appleLoginx2} 768w, ${asset.appleLoginx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          maxWidth: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center mb-4">
                <Col>
                  <hr />
                </Col>
                <Col xs="auto" className="align-self-center">
                  <span className="font-weight-bold font-size-16">{"OR"}</span>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row> */}
              {props.error.error ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {props.error.error}
                </Alert>
              ) : null}
              <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                <Row className="justify-content-center">
                  {/* <Card
                    className="shadow-none align-self-center mb-2"
                    style={{
                      border: "2px solid #F3F4F8",
                      borderRadius: "16px",
                      height: "56px",
                    }}
                  >
                    <Row className="h-100">
                      <Col xs="auto align-self-center pe-0">
                        <img
                          src={asset.iconEmail}
                          srcSet={`${asset.iconEmail} 300w, ${asset.iconEmailx2} 768w, ${asset.iconEmailx3} 1280w`}
                          alt=""
                          style={{
                            width: "24px",
                          }}
                        />
                      </Col>
                      <Col className="align-self-center ps-0">
                        
                      </Col>
                    </Row>
                  </Card> */}
                  <AvField
                    name="email"
                    // label="Email"
                    // value="admin@themesbrand.com"
                    className="form-control font-weight-semibold font-size-16 mb-3"
                    placeholder="Email"
                    type="email"
                    // required
                    validate={{
                      email: {
                        value: true,
                        errorMessage: "Please enter a valid email",
                      },
                      required: {
                        value: true,
                        errorMessage: "Please enter email",
                      },
                    }}
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "16px",
                      height: "56px",
                    }}
                  />
                </Row>
                <Row className="justify-content-center">
                  {/* <Card
                    className="shadow-none align-self-center mb-0"
                    style={{
                      border: "2px solid #F3F4F8",
                      borderRadius: "16px",
                      height: "56px",
                    }}
                  >
                    <Row className="h-100">
                      <Col xs="auto align-self-center pe-0">
                        <img
                          src={asset.iconPassword}
                          srcSet={`${asset.iconPassword} 300w, ${asset.iconPasswordx2} 768w, ${asset.iconPasswordx3} 1280w`}
                          alt=""
                          style={{
                            width: "24px",
                          }}
                        />
                      </Col>
                      <Col className="align-self-center ps-0">
                        
                      </Col>
                    </Row>
                  </Card> */}
                  <AvField
                    name="password"
                    // label="Email"
                    // value="admin@themesbrand.com"
                    className="form-control font-weight-semibold font-size-16 mb-3"
                    placeholder="Password"
                    type="password"
                    // required
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "16px",
                      height: "56px",
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter password",
                      },
                      minLength: {
                        value: 6,
                        errorMessage: "Wrong password, please try again",
                      },
                    }}
                  />
                </Row>

                <Row className="justify-content-center mb-4">
                  <Col
                    className="justify-content-end"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      className="btn btn-link text-secondary font-weight-semibold font-size-14"
                      onClick={props.onForgetPassword}
                    >
                      <em>{"Forget password?"}</em>
                    </span>
                  </Col>
                </Row>

                <Row className="justify-content-center mb-3">
                  <Col className="w-100">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                      type="submit"
                      style={{
                        borderRadius: "16px",
                        height: "56px",
                        fontWeight: 700,
                      }}
                    >
                      {"Sign In"}
                    </button>
                  </Col>
                </Row>
              </AvForm>
              <Row className="justify-content-center mb-5">
                <Col className="w-100">
                  <button
                    className="btn bg-white btn-block waves-effect waves-light w-100 font-size-16"
                    style={{
                      border: "2px solid #F3F4F8",
                      borderRadius: "16px",
                      height: "56px",
                      fontWeight: 700,
                    }}
                    onClick={props.onSignup}
                  >
                    {"New to Hapivity?"}{" "}
                    <span className="text-primary text-weight-bold">
                      {" "}
                      {" Sign up"}
                    </span>
                  </button>
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Col xs="auto">
                  <span className="font-weight-semibold font-size-14">
                    {"Get the App"}
                  </span>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="6" className="p-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-1 px-lg-2 px-xl-3 height-56"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                      onClick={() => {
                        window.open(
                          "https://apps.apple.com/app/hapivity/id1589719031",
                          "_blank"
                        )
                        return null
                      }}
                    >
                      <img
                        src={asset.appstoreDownload}
                        srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                        alt=""
                        style={{
                          height: "100%",
                          // maxWidth: "100%",
                          aspectRatio: "95/22",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col>
                <Col xs="6" className="p-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-1 px-lg-2 px-xl-3 height-56"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                      onClick={() => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.hapivity",
                          "_blank"
                        )
                        return null
                      }}
                    >
                      <img
                        src={asset.googleDownload}
                        srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                        alt=""
                        style={{
                          height: "100%",
                          // maxWidth: "100%",
                          aspectRatio: "95/22",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col>
                {/* <Col xs="4" className="p-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-1 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <img
                        src={asset.huaweiDownload}
                        srcSet={`${asset.huaweiDownload} 300w, ${asset.huaweiDownloadx2} 768w, ${asset.huaweiDownloadx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          maxWidth: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </button>
                  </Card>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(connect(mapStateToProps, { loginUser })(LoginModal))

LoginModal.propTypes = {
  setting: PropTypes.object,
  loginUser: PropTypes.func,
  history: PropTypes.object,
}

// export default LoginModal
