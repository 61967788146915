import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"
import images from "assets/images"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const SignupVerificationModal = props => {
  const [form, setForm] = useState(null)
  const [otp, setOTP] = useState(null)
  const [signupError, setsignup_error] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("hsForm")) {
      setForm(JSON.parse(localStorage.getItem("hsForm")))
    }
    if (localStorage.getItem("hsOTP")) {
      setOTP(JSON.parse(localStorage.getItem("hsOTP")))
    }
  }, [props.isOpen])

  function handleValidSubmit(event, values) {
    setsignup_error(null)
    setLoading(true)
    if (values.otp == otp) {
      signup(values.otp)
    } else {
      setsignup_error("Invalid OTP")
      setLoading(false)
    }
  }

  const signup = async otpInput => {
    let formdata = new FormData()
    formdata.append("email", form.email)
    formdata.append("password", form.password)
    formdata.append("nationality", form.nationality)
    formdata.append("otp", otpInput)
    // formdata.append("promo", form.promotion ? 1 : 0)
    formdata.append("promo", 1)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_SIGNUP, requestOptions)
    const postresponse = await response.json()

    setLoading(false)
    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.removeItem("hsOTP")
        localStorage.removeItem("hsForm")
        localStorage.setItem("hAuth", postresponse.data.remember_token)
        localStorage.setItem("hId", postresponse.data.id)
        props.onLogin()
      } else {
        setsignup_error(postresponse.message)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div
          className="modal-header d-lg-none"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Row className="modal-body p-0" style={{ backgroundColor: "#FBFBFB" }}>
          {/* <Container className="p-0 h-100 w-100">
            <Row>
              
            </Row>
          </Container> */}
          <Col lg={6} className="align-self-center">
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <Row className="justify-content-center m-0">
                <Col md={6} lg={10} xl={8} className="px-3">
                  <Row className="justify-content-center mb-4">
                    <Col xs="auto">
                      <img
                        src={asset.iconLetter}
                        srcSet={`${asset.iconLetter} 300w, ${asset.iconLetterx2} 768w, ${asset.iconLetterx3} 1280w`}
                        alt=""
                        style={{ maxWidth: "72px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                      <span className="font-weight-extrabold font-size-20">
                        {"Check your inbox"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto" className="text-center">
                      <span className="font-weight-semibold font-size-14">
                        {"To verify your email, we've sent a One Time Password (OTP) to " +
                          (form && form.email)}
                      </span>
                      <button
                        className="btn btn-link p-0"
                        onClick={props.onSignup}
                      >
                        {" (Change)"}
                      </button>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconEmail}
                            srcSet={`${asset.iconEmail} 300w, ${asset.iconEmailx2} 768w, ${asset.iconEmailx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="email"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="Email"
                            type="email"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="otp"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="OTP"
                      type="text"
                      // required
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter OTP",
                        },
                      }}
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col className="w-100">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        // onClick={props.onLogin}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
                        )}
                        {"Verify and sign-in"}
                      </button>
                    </Col>
                  </Row>
                  {signupError && (
                    <Row className="mb-3">
                      <Col>
                        <Alert
                          color="danger"
                          role="alert"
                          className="border-radius-8 font-size-14 font-weight-semibold align-middle"
                        >
                          {signupError}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </AvForm>
          </Col>

          <Col
            lg={6}
            className="bg-primary d-flex justify-content-center align-self-center h-100 d-none d-lg-flex"
          >
            <img
              src={asset.signup}
              srcSet={`${asset.signup} 300w, ${asset.signupx2} 768w, ${asset.signupx3} 1280w`}
              alt=""
              style={{
                maxHeight: "80%",
                height: "685",
                width: "510",
                maxWidth: "80%",
                aspectRatio: "102/137",
              }}
              className="mx-auto my-auto"
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

SignupVerificationModal.propTypes = {
  setting: PropTypes.object,
}

export default SignupVerificationModal
