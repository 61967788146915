import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"
import images from "assets/images"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const SignupModal = props => {
  const [signupError, setsignup_error] = useState(null)
  const [loading, setLoading] = useState(false)

  function handleValidSubmit(event, values) {
    setsignup_error(null)
    console.log(values)
    if (values.tnc) {
      setLoading(true)
      signup(values)
    } else {
      setsignup_error(
        "Please agree to the Terms of Service and Privacy Statement to continue"
      )
    }
    // login(values)
    // props.loginUser(values, props.history)
  }

  const signup = async values => {
    let formdata = new FormData()
    formdata.append("email", values.email)
    formdata.append("action", "registration")

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_REQUESTOTP, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    setLoading(false)
    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.setItem("hsOTP", postresponse.data.otp)
        localStorage.setItem("hsForm", JSON.stringify(values))
        // localStorage.setItem("hId", postresponse.data.id)
        // props.onLogin(postresponse.data.id, postresponse.data.remember_token)
        props.onVerify()
      } else {
        setsignup_error(postresponse.message)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div
          className="modal-header d-lg-none"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Row className="modal-body p-0" style={{ backgroundColor: "#FBFBFB" }}>
          {/* <Container className="p-0 h-100 w-100">
            <Row>
              
            </Row>
          </Container> */}
          <Col
            lg={6}
            className="align-self-center"
            style={{ overflowY: "auto", maxHeight: "100%" }}
          >
            <Row className="justify-content-center m-0">
              <Col md={6} lg={10} xl={8} className="px-3">
                <Row className="justify-content-center mb-4 mt-4">
                  <Col xs="auto">
                    <img
                      src={asset.logo}
                      alt=""
                      style={{ maxWidth: "170px", cursor: "pointer" }}
                      onClick={props.toggle}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col xs="auto" className="text-center">
                    <span className="font-weight-extrabold font-size-20">
                      {
                        "Sign up to experience the features packed Virtual Challenge"
                      }
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col xs="auto">
                    <span className="font-weight-bold font-size-16 opacity-70">
                      {"Start for free"}
                    </span>
                  </Col>
                </Row>
                {/* <Row className="justify-content-center">
                  <Col xs="4" className="px-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-0 px-lg-2 px-xl-3"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                      >
                        <img
                          src={asset.googleLogin}
                          srcSet={`${asset.googleLogin} 300w, ${asset.googleLoginx2} 768w, ${asset.googleLoginx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            maxWidth: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col>
                  <Col xs="4" className="px-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-0 px-lg-2 px-xl-3"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                      >
                        <img
                          src={asset.facebookLogin}
                          srcSet={`${asset.facebookLogin} 300w, ${asset.facebookLoginx2} 768w, ${asset.facebookLoginx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            maxWidth: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col>
                  <Col xs="4" className="px-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-0 px-lg-2 px-xl-3"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                      >
                        <img
                          src={asset.appleLogin}
                          srcSet={`${asset.appleLogin} 300w, ${asset.appleLoginx2} 768w, ${asset.appleLoginx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            maxWidth: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col>
                </Row> */}
                {/* <Row className="justify-content-center mb-4">
                  <Col>
                    <hr />
                  </Col>
                  <Col xs="auto" className="align-self-center">
                    <span className="font-weight-bold font-size-16">
                      {"OR"}
                    </span>
                  </Col>
                  <Col>
                    <hr />
                  </Col>
                </Row> */}
                <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center mb-2"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconEmail}
                            srcSet={`${asset.iconEmail} 300w, ${asset.iconEmailx2} 768w, ${asset.iconEmailx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="email"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="Email"
                            type="email"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="email"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="Email"
                      type="email"
                      // required
                      validate={{
                        email: {
                          value: true,
                          errorMessage: "Please enter a valid email",
                        },
                        required: {
                          value: true,
                          errorMessage: "Please enter email",
                        },
                      }}
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center mb-0"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconPassword}
                            srcSet={`${asset.iconPassword} 300w, ${asset.iconPasswordx2} 768w, ${asset.iconPasswordx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="email"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="Password"
                            type="password"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="password"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="Password"
                      type="password"
                      // required
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter password",
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Your password must be more than 6 characters",
                        },
                      }}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <span className="font-weight-bold font-size-16 text-secondary">
                        {"Nationality*"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <span className="font-weight-normal font-size-14 color-62697E">
                        <i className="mdi mdi-lock font-size-14 align-middle me-1" />
                        <em>
                          {"You cannot change your Nationality after this."}
                        </em>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AvField
                        name="nationality"
                        value={"my"}
                        className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-ced4da"
                        // placeholder="Country *"
                        type="select"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please choose your nationality",
                          },
                        }}
                        onChange={e => {
                          // setselected_country(e.target.value)
                        }}
                      >
                        {/* {country.COUNTRY_LIST.map((object, index) => (
                        <option value={object.code}>{object.country}</option>
                      ))} */}
                        <option value="au">Australia</option>
                        <option value="id">Indonesia</option>
                        <option value="my">Malaysia</option>
                        <option value="sg">Singapore</option>
                        <option value="gb">United Kingdom</option>
                      </AvField>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-4">
                    <Col className="w-100">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        // onClick={props.onVerify}
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
                        )}
                        {"Verify email"}
                      </button>
                    </Col>
                  </Row>
                  {signupError && (
                    <Row className="mb-3">
                      <Col>
                        <Alert
                          color="danger"
                          role="alert"
                          className="border-radius-8 font-size-14 font-weight-semibold align-middle"
                        >
                          {signupError}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                      <AvInput type="checkbox" name="tnc" />
                    </Col>
                    <Col className="ps-0">
                      <p className="mb-0 font-size-14 font-weight-semibold">
                        I agree to the{" "}
                        <Link href="#" className="text-primary">
                          Terms of Service
                        </Link>
                        {" and "}
                        <Link href="#" className="text-primary">
                          Privacy Statement
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  {/* <Row className="justify-content-center mb-4">
                    <Col xs="auto">
                      <AvInput type="checkbox" name="promotion" />
                    </Col>
                    <Col className="ps-0">
                      <p className="mb-0 font-size-14 font-weight-semibold">
                        Send me promotional emails. Unsubscribe anytime
                      </p>
                    </Col>
                  </Row> */}
                </AvForm>
                <Row className="justify-content-center mb-3">
                  <Col className="w-100">
                    <button
                      className="btn bg-white btn-block waves-effect waves-light w-100 font-size-16"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                        fontWeight: 700,
                      }}
                      onClick={props.onLogin}
                    >
                      {"Have an account?"}{" "}
                      <span className="text-primary text-weight-bold">
                        {" "}
                        {" Login"}
                      </span>
                    </button>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col xs="auto">
                    <span className="font-weight-semibold font-size-14">
                      {"Get the App"}
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="6" className="p-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-1 px-lg-2 px-xl-3 height-56"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          window.open(
                            "https://apps.apple.com/app/hapivity/id1589719031",
                            "_blank"
                          )
                          return null
                        }}
                      >
                        <img
                          src={asset.appstoreDownload}
                          srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                          alt=""
                          style={{
                            height: "100%",
                            // maxWidth: "100%",
                            aspectRatio: "95/22",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col>
                  <Col xs="6" className="p-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-1 px-lg-2 px-xl-3 height-56"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.hapivity",
                            "_blank"
                          )
                          return null
                        }}
                      >
                        <img
                          src={asset.googleDownload}
                          srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                          alt=""
                          style={{
                            height: "100%",
                            // maxWidth: "100%",
                            aspectRatio: "95/22",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col>
                  {/* <Col xs="4" className="p-1 px-lg-2">
                    <Card className="shadow-none bg-transparent">
                      <button
                        className="btn bg-white px-1 px-lg-2 px-xl-3"
                        style={{
                          border: "2px solid #F3F4F8",
                          borderRadius: "16px",
                        }}
                      >
                        <img
                          src={asset.huaweiDownload}
                          srcSet={`${asset.huaweiDownload} 300w, ${asset.huaweiDownloadx2} 768w, ${asset.huaweiDownloadx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            maxWidth: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </button>
                    </Card>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col
            lg={6}
            className="bg-white d-flex justify-content-center align-self-center h-100 d-none d-lg-flex"
          >
            <img
              src={"https://hapivity.imgix.net/home/img-signup.jpg"}
              srcSet={`${"https://hapivity.imgix.net/home/img-signup.jpg"} 300w, ${"https://hapivity.imgix.net/home/img-signup.jpg"} 768w, ${"https://hapivity.imgix.net/home/img-signup.jpg"} 1280w`}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                // height: "685",
                // width: "510",
                // maxWidth: "100%",
                // aspectRatio: "102/137",
              }}
              className="mx-auto my-auto"
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

SignupModal.propTypes = {
  setting: PropTypes.object,
}

export default SignupModal
