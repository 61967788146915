// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SHOW_LOGIN,
  SHOW_JOIN_NOW,
  SHOW_FORGET_PASSWORD,
  SHOW_RESET_PASSWORD,
  SHOW_SIGNUP_VERIFICATION,
  SHOW_REFERRAL,
  SHOW_ONBOARD,
} from "./actionTypes"

const INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "light",
  leftSideBarType: "condensed",
  topbarTheme: "light",
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showLogin: false,
  showJoinNow: false,
  showForgetPassword: false,
  showResetPassword: false,
  showSignupVerification: false,
  showReferral: false,
  showOnboard: false,
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_LOGIN:
      return {
        ...state,
        showLogin: action.payload,
      }
    case SHOW_JOIN_NOW:
      return {
        ...state,
        showJoinNow: action.payload,
      }
    case SHOW_FORGET_PASSWORD:
      return {
        ...state,
        showForgetPassword: action.payload,
      }
    case SHOW_RESET_PASSWORD:
      return {
        ...state,
        showResetPassword: action.payload,
      }
    case SHOW_SIGNUP_VERIFICATION:
      return {
        ...state,
        showSignupVerification: action.payload,
      }
    case SHOW_REFERRAL:
      return {
        ...state,
        showReferral: action.payload,
      }
    case SHOW_ONBOARD:
      return {
        ...state,
        showOnboard: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    default:
      return state
  }
}

export default Layout
