import logo from "assets/images/hapivity/logo/logo.png"

import logoHeader from "assets/images/hapivity/logo/logo_header.png"
import logoHeaderx2 from "assets/images/hapivity/logo/logo_headerx2.png"
import logoHeaderx3 from "assets/images/hapivity/logo/logo_headerx3.png"

import signup from "assets/images/hapivity/signup.png"
import signupx2 from "assets/images/hapivity/signupx2.png"
import signupx3 from "assets/images/hapivity/signupx3.png"

import logoReferral from "assets/images/hapivity/logo_referral.png"
import logoReferralx2 from "assets/images/hapivity/logo_referral@2x.png"
import logoReferralx3 from "assets/images/hapivity/logo_referral@3x.png"

import appleLogin from "assets/images/hapivity/button/apple.png"
import appleLoginx2 from "assets/images/hapivity/button/applex2.png"
import appleLoginx3 from "assets/images/hapivity/button/applex3.png"

import facebookLogin from "assets/images/hapivity/button/facebook.png"
import facebookLoginx2 from "assets/images/hapivity/button/facebookx2.png"
import facebookLoginx3 from "assets/images/hapivity/button/facebookx3.png"

import googleLogin from "assets/images/hapivity/button/google.png"
import googleLoginx2 from "assets/images/hapivity/button/googlex2.png"
import googleLoginx3 from "assets/images/hapivity/button/googlex3.png"

import googleDownload from "assets/images/hapivity/button/download_googleplay.png"
import googleDownloadx2 from "assets/images/hapivity/button/download_googleplayx2.png"
import googleDownloadx3 from "assets/images/hapivity/button/download_googleplayx3.png"

import appstoreDownload from "assets/images/hapivity/button/download_appstore.png"
import appstoreDownloadx2 from "assets/images/hapivity/button/download_appstorex2.png"
import appstoreDownloadx3 from "assets/images/hapivity/button/download_appstorex3.png"

import huaweiDownload from "assets/images/hapivity/button/download_huawei.png"
import huaweiDownloadx2 from "assets/images/hapivity/button/download_huaweix2.png"
import huaweiDownloadx3 from "assets/images/hapivity/button/download_huaweix3.png"

import iconEmail from "assets/images/hapivity/icon/email.png"
import iconEmailx2 from "assets/images/hapivity/icon/emailx2.png"
import iconEmailx3 from "assets/images/hapivity/icon/emailx3.png"

import iconPassword from "assets/images/hapivity/icon/password.png"
import iconPasswordx2 from "assets/images/hapivity/icon/passwordx2.png"
import iconPasswordx3 from "assets/images/hapivity/icon/passwordx3.png"

import iconLetter from "assets/images/hapivity/icon/letter.png"
import iconLetterx2 from "assets/images/hapivity/icon/letter@2x.png"
import iconLetterx3 from "assets/images/hapivity/icon/letter@3x.png"

import iconLock from "assets/images/hapivity/icon/lock.png"
import iconLockx2 from "assets/images/hapivity/icon/lock@2x.png"
import iconLockx3 from "assets/images/hapivity/icon/lock@3x.png"

import iconMessage from "assets/images/hapivity/icon/message.png"
import iconMessagex2 from "assets/images/hapivity/icon/message@2x.png"
import iconMessagex3 from "assets/images/hapivity/icon/message@3x.png"

import iconBag from "assets/images/hapivity/icon/bag.png"
import iconBagx2 from "assets/images/hapivity/icon/bag@2x.png"
import iconBagx3 from "assets/images/hapivity/icon/bag@3x.png"

import iconBagDark from "assets/images/hapivity/icon/bag_dark.png"
import iconBagDarkx2 from "assets/images/hapivity/icon/bag_dark@2x.png"
import iconBagDarkx3 from "assets/images/hapivity/icon/bag_dark@3x.png"

import iconHeart from "assets/images/hapivity/icon/heart.png"
import iconHeartx2 from "assets/images/hapivity/icon/heart@2x.png"
import iconHeartx3 from "assets/images/hapivity/icon/heart@3x.png"

import iconHeartActive from "assets/images/hapivity/icon/heart_active.png"
import iconHeartActivex2 from "assets/images/hapivity/icon/heart_active@2x.png"
import iconHeartActivex3 from "assets/images/hapivity/icon/heart_active@3x.png"

import iconSignout from "assets/images/hapivity/icon/icon-signout.png"
import iconSignoutx2 from "assets/images/hapivity/icon/icon-signout@2x.png"
import iconSignoutx3 from "assets/images/hapivity/icon/icon-signout@3x.png"

import heroHome from "assets/images/hapivity/home/hero.png"
import heroHomex2 from "assets/images/hapivity/home/hero@2x.png"
import heroHomex3 from "assets/images/hapivity/home/hero@3x.png"

import iconWallet from "assets/images/hapivity/icon/icon-wallet.png"
import iconWalletx2 from "assets/images/hapivity/icon/icon-wallet@2x.png"
import iconWalletx3 from "assets/images/hapivity/icon/icon-wallet@3x.png"

import iconShippingTime from "assets/images/hapivity/icon/truck-time.png"
import iconShippingTimex2 from "assets/images/hapivity/icon/truck-time@2x.png"
import iconShippingTimex3 from "assets/images/hapivity/icon/truck-time@3x.png"

import iconShippingFee from "assets/images/hapivity/icon/lorry.png"
import iconShippingFeex2 from "assets/images/hapivity/icon/lorry@2x.png"
import iconShippingFeex3 from "assets/images/hapivity/icon/lorry@3x.png"

import iconMalaysia from "assets/images/hapivity/icon/malaysia.png"
import iconMalaysiax2 from "assets/images/hapivity/icon/malaysia@2x.png"
import iconMalaysiax3 from "assets/images/hapivity/icon/malaysia@3x.png"

import iconUK from "assets/images/hapivity/icon/iconUK.png"
import iconUKx2 from "assets/images/hapivity/icon/iconUK@2x.png"
import iconUKx3 from "assets/images/hapivity/icon/iconUK@3x.png"

import howJoin from "assets/images/hapivity/home/join.png"
import howJoinx2 from "assets/images/hapivity/home/join@2x.png"
import howJoinx3 from "assets/images/hapivity/home/join@3x.png"

import howDownload from "assets/images/hapivity/home/download.png"
import howDownloadx2 from "assets/images/hapivity/home/download@2x.png"
import howDownloadx3 from "assets/images/hapivity/home/download@3x.png"

import howMap from "assets/images/hapivity/home/map.png"
import howMapx2 from "assets/images/hapivity/home/map@2x.png"
import howMapx3 from "assets/images/hapivity/home/map@3x.png"

import getBib from "assets/images/hapivity/home/get/get_bib.png"
import getBibx2 from "assets/images/hapivity/home/get/get_bib@2x.png"
import getBibx3 from "assets/images/hapivity/home/get/get_bib@3x.png"

import getCert from "assets/images/hapivity/home/get/get_cert.png"
import getCertx2 from "assets/images/hapivity/home/get/get_cert@2x.png"
import getCertx3 from "assets/images/hapivity/home/get/get_cert@3x.png"

import getEntry from "assets/images/hapivity/home/get/get_cert.png"
import getEntryx2 from "assets/images/hapivity/home/get/get_cert@2x.png"
import getEntryx3 from "assets/images/hapivity/home/get/get_cert@3x.png"

import getMedal from "assets/images/hapivity/home/get/get_medal.png"
import getMedalx2 from "assets/images/hapivity/home/get/get_medal@2x.png"
import getMedalx3 from "assets/images/hapivity/home/get/get_medal@3x.png"

import getTicket from "assets/images/hapivity/home/get/get_ticket.png"
import getTicketx2 from "assets/images/hapivity/home/get/get_ticket@2x.png"
import getTicketx3 from "assets/images/hapivity/home/get/get_ticket@3x.png"

import contactHelp from "assets/images/hapivity/home/contact/help.png"
import contactHelpx2 from "assets/images/hapivity/home/contact/help@2x.png"
import contactHelpx3 from "assets/images/hapivity/home/contact/help@3x.png"

import contactEmail from "assets/images/hapivity/home/contact/email.png"
import contactEmailx2 from "assets/images/hapivity/home/contact/email@2x.png"
import contactEmailx3 from "assets/images/hapivity/home/contact/email@3x.png"

import contactChat from "assets/images/hapivity/home/contact/chat.png"
import contactChatx2 from "assets/images/hapivity/home/contact/chat@2x.png"
import contactChatx3 from "assets/images/hapivity/home/contact/chat@3x.png"

import iconCheckoutMobile from "assets/images/hapivity/icon/navbar/mobile.png"
import iconCheckoutMobilex2 from "assets/images/hapivity/icon/navbar/mobile@2x.png"
import iconCheckoutMobilex3 from "assets/images/hapivity/icon/navbar/mobile@3x.png"

import iconCheckoutGlobal from "assets/images/hapivity/icon/navbar/global.png"
import iconCheckoutGlobalx2 from "assets/images/hapivity/icon/navbar/global@2x.png"
import iconCheckoutGlobalx3 from "assets/images/hapivity/icon/navbar/global@3x.png"

import iconCheckoutSecure from "assets/images/hapivity/icon/navbar/secure.png"
import iconCheckoutSecurex2 from "assets/images/hapivity/icon/navbar/secure@2x.png"
import iconCheckoutSecurex3 from "assets/images/hapivity/icon/navbar/secure@3x.png"

import iconFacebook from "assets/images/hapivity/icon/facebook.png"
import iconFacebookx2 from "assets/images/hapivity/icon/facebook@2x.png"
import iconFacebookx3 from "assets/images/hapivity/icon/facebook@3x.png"

import iconInstagram from "assets/images/hapivity/icon/instagram.png"
import iconInstagramx2 from "assets/images/hapivity/icon/instagram@2x.png"
import iconInstagramx3 from "assets/images/hapivity/icon/instagram@3x.png"

import iconPaymentMethod from "assets/images/hapivity/payment_method.png"
import iconPaymentMethodx2 from "assets/images/hapivity/payment_method@2x.png"
import iconPaymentMethodx3 from "assets/images/hapivity/payment_method@3x.png"

import iconDeliveryGuarantee from "assets/images/hapivity/icon/icon_delivery_guarantee.png"
import iconDeliveryGuaranteex2 from "assets/images/hapivity/icon/icon_delivery_guarantee@2x.png"
import iconDeliveryGuaranteex3 from "assets/images/hapivity/icon/icon_delivery_guarantee@3x.png"

//temp
import medal from "assets/images/hapivity/temp/medal.png"
import medalx2 from "assets/images/hapivity/temp/medal@2x.png"
import medalx3 from "assets/images/hapivity/temp/medal@3x.png"
import tempPackage from "assets/images/hapivity/temp/tempPackage.png"

import features from "assets/images/hapivity/home/features/features.png"
import featuresx2 from "assets/images/hapivity/home/features/features@2x.png"
import featuresx3 from "assets/images/hapivity/home/features/features@3x.png"

import profile from "assets/images/hapivity/temp/profile.png"
import address from "assets/images/hapivity/temp/address.png"
import order from "assets/images/hapivity/temp/order.png"
import editaddress from "assets/images/hapivity/temp/editaddress.png"

const asset = {
  logo: logo,
  logoHeader: logoHeader,
  logoHeaderx2: logoHeaderx2,
  logoHeaderx3: logoHeaderx3,
  signup: signup,
  signupx2: signupx2,
  signupx3: signupx3,
  logoReferral: logoReferral,
  logoReferralx2: logoReferralx2,
  logoReferralx3: logoReferralx3,
  appleLogin: appleLogin,
  appleLoginx2: appleLoginx2,
  appleLoginx3: appleLoginx3,
  facebookLogin: facebookLogin,
  facebookLoginx2: facebookLoginx2,
  facebookLoginx3: facebookLoginx3,
  googleLogin: googleLogin,
  googleLoginx2: googleLoginx2,
  googleLoginx3: googleLoginx3,
  iconEmail: iconEmail,
  iconEmailx2: iconEmailx2,
  iconEmailx3: iconEmailx3,
  iconPassword: iconPassword,
  iconPasswordx2: iconPasswordx2,
  iconPasswordx3: iconPasswordx3,
  iconLetter: iconLetter,
  iconLetterx2: iconLetterx2,
  iconLetterx3: iconLetterx3,
  iconLock: iconLock,
  iconLockx2: iconLockx2,
  iconLockx3: iconLockx3,
  iconBag: iconBag,
  iconBagx2: iconBagx2,
  iconBagx3: iconBagx3,
  iconBagDark: iconBagDark,
  iconBagDarkx2: iconBagDarkx2,
  iconBagDarkx3: iconBagDarkx3,
  iconHeart: iconHeart,
  iconHeartx2: iconHeartx2,
  iconHeartx3: iconHeartx3,
  iconHeartActive: iconHeartActive,
  iconHeartActivex2: iconHeartActivex2,
  iconHeartActivex3: iconHeartActivex3,
  iconSignout: iconSignout,
  iconSignoutx2: iconSignoutx2,
  iconSignoutx3: iconSignoutx3,
  iconMessage: iconMessage,
  iconMessagex2: iconMessagex2,
  iconMessagex3: iconMessagex3,
  googleDownload: googleDownload,
  googleDownloadx2: googleDownloadx2,
  googleDownloadx3: googleDownloadx3,
  appstoreDownload: appstoreDownload,
  appstoreDownloadx2: appstoreDownloadx2,
  appstoreDownloadx3: appstoreDownloadx3,
  huaweiDownload: huaweiDownload,
  huaweiDownloadx2: huaweiDownloadx2,
  huaweiDownloadx3: huaweiDownloadx3,
  heroHome: heroHome,
  heroHomex2: heroHomex2,
  heroHomex3: heroHomex3,
  iconMalaysia: iconMalaysia,
  iconMalaysiax2: iconMalaysiax2,
  iconMalaysiax3: iconMalaysiax3,
  iconUK: iconUK,
  iconUKx2: iconUKx2,
  iconUKx3: iconUKx3,
  iconWallet: iconWallet,
  iconWalletx2: iconWalletx2,
  iconWalletx3: iconWalletx3,
  iconShippingFee: iconShippingFee,
  iconShippingFeex2: iconShippingFeex2,
  iconShippingFeex3: iconShippingFeex3,
  iconShippingTime: iconShippingTime,
  iconShippingTimex2: iconShippingTimex2,
  iconShippingTimex3: iconShippingTimex3,
  iconPaymentMethod: iconPaymentMethod,
  iconPaymentMethodx2: iconPaymentMethodx2,
  iconPaymentMethodx3: iconPaymentMethodx3,
  howJoin: howJoin,
  howJoinx2: howJoinx2,
  howJoinx3: howJoinx3,
  howDownload: howDownload,
  howDownloadx2: howDownloadx2,
  howDownloadx3: howDownloadx3,
  howMap: howMap,
  howMapx2: howMapx2,
  howMapx3: howMapx3,
  getBib: getBib,
  getBibx2: getBibx2,
  getBibx3: getBibx3,
  getEntry: getEntry,
  getEntryx2: getEntryx2,
  getEntryx3: getEntryx3,
  getCert: getCert,
  getCertx2: getCertx2,
  getCertx3: getCertx3,
  getMedal: getMedal,
  getMedalx2: getMedalx2,
  getMedalx3: getMedalx3,
  getTicket: getTicket,
  getTicketx2: getTicketx2,
  getTicketx3: getTicketx3,
  contactChat: contactChat,
  contactChatx2: contactChatx2,
  contactChatx3: contactChatx3,
  contactHelp: contactHelp,
  contactHelpx2: contactHelpx2,
  contactHelpx3: contactHelpx3,
  contactEmail: contactEmail,
  contactEmailx2: contactEmailx2,
  contactEmailx3: contactEmailx3,
  iconCheckoutGlobal: iconCheckoutGlobal,
  iconCheckoutGlobalx2: iconCheckoutGlobalx2,
  iconCheckoutGlobalx3: iconCheckoutGlobalx3,
  iconCheckoutMobile: iconCheckoutMobile,
  iconCheckoutMobilex2: iconCheckoutMobilex2,
  iconCheckoutMobilex3: iconCheckoutMobilex3,
  iconCheckoutSecure: iconCheckoutSecure,
  iconCheckoutSecurex2: iconCheckoutSecurex2,
  iconCheckoutSecurex3: iconCheckoutSecurex3,
  iconDeliveryGuarantee: iconDeliveryGuarantee,
  iconDeliveryGuaranteex2: iconDeliveryGuaranteex2,
  iconDeliveryGuaranteex3: iconDeliveryGuaranteex3,
  iconFacebook: iconFacebook,
  iconFacebookx2: iconFacebookx2,
  iconFacebookx3: iconFacebookx3,
  iconInstagram: iconInstagram,
  iconInstagramx2: iconInstagramx2,
  iconInstagramx3: iconInstagramx3,
  //temp
  medal: medal,
  medalx2: medalx2,
  medalx3: medalx3,
  tempPackage: tempPackage,
  features: features,
  featuresx2: featuresx2,
  featuresx3: featuresx3,
  profile: profile,
  address: address,
  order: order,
  editaddress: editaddress,
}
export { asset }
