import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Collapse,
} from "reactstrap"
import { Link, withRouter, useHistory } from "react-router-dom"
import classname from "classnames"

import {
  toggleLeftmenu,
  showRightSidebarAction,
  showLoginAction,
  showJoinNowAction,
  showForgetPasswordAction,
  showResetPasswordAction,
  showSignupVerificationAction,
  showReferralAction,
  showOnboardAction,
} from "../../store/actions"
//i18n
import { withTranslation } from "react-i18next"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

import { connect } from "react-redux"
import { logoutUser, loginUser } from "store/actions"

import LoginModal from "pages/Hapivity/LoginModal"
import SignupModal from "pages/Hapivity/SignupModal"
import SignupVerificationModal from "pages/Hapivity/SignupVerificationModal"
import ForgetPasswordModal from "pages/Hapivity/ForgetPasswordModal"
import ResetPasswordModal from "pages/Hapivity/ResetPasswordModal"
import ReferralModal from "pages/Hapivity/ReferralModal"
import OnboardModal from "pages/Hapivity/OnboardModal"

const Navbar = props => {
  const history = useHistory()

  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  const [isCheckout, setisCheckout] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      // console.log(props.location.pathname + " : " + items[i].pathname)
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
    if (
      props.location.pathname === "/store-checkout" ||
      props.location.pathname === "/store-payment"
    ) {
      setisCheckout(true)
    }
    if (localStorage.getItem("hAuth")) {
      setremember_token(localStorage.getItem("hAuth"))
    }
    if (localStorage.getItem("hId")) {
      setuid(localStorage.getItem("hId"))
    }
  }, [])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const [uid, setuid] = useState(null)
  const [remember_token, setremember_token] = useState(null)
  const [user, setuser] = useState(null)
  const [onboard, setOnboard] = useState(false)

  useEffect(() => {
    if (remember_token && uid) {
      login(uid, remember_token)
      props.loginUser({ uid: uid, token: remember_token }, props.history)
    }
  }, [remember_token, uid])

  const login = async (uid, token) => {
    let formdata = new FormData()
    formdata.append("uid", uid)
    formdata.append("token", token)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_LOGIN_TOKEN, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      // console.log()
      if (postresponse.success) {
        setuser(postresponse.data)
        setOnboard(postresponse.data.onboard_web)
      }
    }
  }

  useEffect(() => {
    if (user) {
      localStorage.setItem("hUser", JSON.stringify(user))
    }
  }, [user])

  function logout() {
    props.logoutUser(props.history)
    setuser(null)
    setremember_token(null)
    setuid(null)
    history.push("/home")
  }

  //modal login
  // const [modal_login, setmodal_login] = useState(false)
  // const [modal_signup, setmodal_signup] = useState(false)
  // const [modal_forget_password, setmodal_forget_password] = useState(false)
  // const [modal_reset_password, setmodal_reset_password] = useState(false)
  // const [modal_signup_verification, setmodal_signup_verification] = useState(
  //   false
  // )
  // const [modal_referral, setmodal_referral] = useState(false)
  // const [modal_onboard, setmodal_onboard] = useState(false)

  function tog_login() {
    // setmodal_login(!modal_login)
    props.showLoginAction(!props.showLogin)
    removeBodyCss()
  }

  function tog_signup() {
    // setmodal_signup(!modal_signup)
    props.showJoinNowAction(!props.showJoinNow)
    removeBodyCss()
  }

  function tog_forget_password() {
    // setmodal_forget_password(!modal_forget_password)
    props.showForgetPasswordAction(!props.showForgetPassword)
    removeBodyCss()
  }

  function tog_reset_password() {
    // setmodal_reset_password(!modal_reset_password)
    props.showResetPasswordAction(!props.showResetPassword)
    removeBodyCss()
  }

  function tog_signup_verification() {
    // setmodal_signup_verification(!modal_signup_verification)
    props.showSignupVerificationAction(!props.showSignupVerification)
    removeBodyCss()
  }

  function tog_referral() {
    // setmodal_referral(!modal_referral)
    props.showReferralAction(!props.showReferral)
    removeBodyCss()
  }

  function tog_onboard() {
    // setmodal_onboard(!modal_onboard)
    props.showOnboardAction(!props.showOnboard)
    setOnboard(false)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    console.log("onboard", onboard)
    if (onboard) {
      tog_onboard()
    }
  }, [onboard])

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          {isCheckout ? (
            <Row>
              <Col xs="auto" className="my-auto">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                  className="btn btn-link text-primary px-0 me-3"
                  // data-dismiss="modal"
                  // aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    className="font-weight-bold font-size-14 align-middle"
                  >
                    <i className="mdi mdi-chevron-left font-size-14 "></i>
                    {" Back"}
                  </span>
                </button>
              </Col>
              <Col>
                <Row className="justify-content-center">
                  <Col xs="auto" className="py-2">
                    <span className="logo-md">
                      <img
                        src={asset.logoHeader}
                        srcSet={`${asset.logoHeader} 300w, ${asset.logoHeaderx2} 768w, ${asset.logoHeaderx3} 1280w`}
                        alt=""
                        height="58"
                        className="py-2 me-3"
                      />
                    </span>
                    <span className="font-weight-bold text-black align-middle checkout-header">
                      {"Secure Checkout"}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="my-auto d-none d-lg-block">
                <Row>
                  {/* <Col style={{ width: "100px" }}>
                    <Row className="justify-content-center py-1">
                      <img
                        src={asset.iconCheckoutMobile}
                        srcSet={`${asset.iconCheckoutMobile} 300w, ${asset.iconCheckoutMobilex2} 768w, ${asset.iconCheckoutMobilex3} 1280w`}
                        alt=""
                        style={{ width: "auto", height: "16px" }}
                        className="opacity-50"
                      />
                    </Row>
                    <Row>
                      <span className="font-size-12 font-weight-semibold text-secondary text-center opacity-50">
                        {"Full-fledged Mobile Apps"}
                      </span>
                    </Row>
                  </Col>
                  <Col style={{ width: "100px" }}>
                    <Row className="justify-content-center py-1">
                      <img
                        src={asset.iconCheckoutGlobal}
                        srcSet={`${asset.iconCheckoutGlobal} 300w, ${asset.iconCheckoutGlobalx2} 768w, ${asset.iconCheckoutGlobalx3} 1280w`}
                        alt=""
                        style={{ width: "auto", height: "16px" }}
                        className="opacity-50"
                      />
                    </Row>
                    <Row>
                      <span className="font-size-12 font-weight-semibold text-secondary text-center opacity-50">
                        {"Global Delivery"}
                      </span>
                    </Row>
                  </Col>
                  <Col style={{ width: "100px" }}>
                    <Row className="justify-content-center py-1">
                      <img
                        src={asset.iconCheckoutSecure}
                        srcSet={`${asset.iconCheckoutSecure} 300w, ${asset.iconCheckoutSecurex2} 768w, ${asset.iconCheckoutSecurex3} 1280w`}
                        alt=""
                        style={{ width: "auto", height: "16px" }}
                        className="opacity-50"
                      />
                    </Row>
                    <Row>
                      <span className="font-size-12 font-weight-semibold text-secondary text-center opacity-50">
                        {"Secure Payment"}
                      </span>
                    </Row>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          ) : (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <div className="navbar-brand-box">
                <Link to="/" className="logo">
                  {/* <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span> */}
                  <span className="logo-md">
                    <img
                      src={asset.logoHeader}
                      srcSet={`${asset.logoHeader} 300w, ${asset.logoHeaderx2} 768w, ${asset.logoHeaderx3} 1280w`}
                      alt=""
                      height="58"
                      className="py-2"
                    />
                  </span>
                </Link>
              </div>
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse d-none d-lg-block"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/home">
                      {props.t("Home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/store">
                      {props.t("Store")}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/">
                      {props.t("Features")}
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      {props.t("About")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/help"
                      // onClick={() => {
                      //   window.open("https://help.hapivity.com/", "_blank")
                      //   return null
                      // }}
                    >
                      {props.t("Help")}
                    </Link>
                  </li>
                </ul>
              </Collapse>
              <div className="d-flex">
                {!user && (
                  <button
                    className="btn btn-link text-primary font-weight-extrabold font-size-14 me-3 d-none d-lg-block"
                    onClick={tog_login}
                  >
                    {"Sign in"}
                  </button>
                )}
                {user && (
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => {
                      setDropdownOpen(!dropdownOpen)
                    }}
                  >
                    <DropdownToggle
                      className="btn text-primary font-weight-extrabold font-size-14 me-3 d-none d-lg-block"
                      tag="span"
                    >
                      {user.display_name}
                    </DropdownToggle>
                    <DropdownMenu
                      className="mt-4"
                      style={{
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "16px",
                        padding: "12px 0px",
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/dashboard",
                          dashboardSelect: "profile",
                        }}
                      >
                        <div
                          className="font-weight-bold font-size-14 text-secondary mt-2 mb-2"
                          style={{
                            marginLeft: "18px",
                          }}
                        >
                          My Profile
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/dashboard",
                          dashboardSelect: "order",
                        }}
                      >
                        <div
                          className="font-weight-bold font-size-14 text-secondary mb-2"
                          style={{
                            marginLeft: "18px",
                          }}
                        >
                          My Order
                        </div>
                      </Link>
                      <Link
                        to={{
                          pathname: "/dashboard",
                          dashboardSelect: "address",
                        }}
                      >
                        <div
                          className="font-weight-bold font-size-14 text-secondary mb-1"
                          style={{
                            marginLeft: "18px",
                          }}
                        >
                          Address Book
                        </div>
                      </Link>
                    </DropdownMenu>
                  </Dropdown>
                )}
                {!user && (
                  <button
                    className="btn btn-primary font-weight-extrabold font-size-14 me-3 d-none d-lg-block"
                    style={{ borderRadius: "14px" }}
                    onClick={tog_signup}
                  >
                    {"Join now"}
                  </button>
                )}
                {user && (
                  <button
                    className="btn btn-primary font-weight-extrabold font-size-14 me-3 d-none d-lg-block"
                    style={{ borderRadius: "14px" }}
                    onClick={logout}
                  >
                    {"Log out"}
                  </button>
                )}
                {/* <button className="btn bg-white d-none d-lg-block">
                  <img
                    src={asset.iconBag}
                    srcSet={`${asset.iconBag} 300w, ${asset.iconBagx2} 768w, ${asset.iconBagx3} 1280w`}
                    alt=""
                    height="24"
                    // className="py-2"
                  />
                </button> */}
                <button
                  // type="button"
                  className="btn font-size-16 d-lg-none text-primary"
                  // data-toggle="collapse"
                  onClick={() => {
                    props.showRightSidebarAction(!props.showRightSidebar)
                  }}
                  // data-target="#topnav-menu-content"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
              </div>
            </nav>
          )}
        </div>
      </div>
      <LoginModal
        // isOpen={modal_login}
        isOpen={props.showLogin}
        toggle={() => {
          tog_login()
        }}
        onSignup={() => {
          // setmodal_login(false)
          tog_login()
          tog_signup()
        }}
        onForgetPassword={() => {
          // setmodal_login(false)
          tog_login()
          tog_forget_password()
        }}
        onLogin={(uid, token) => {
          setremember_token(token)
          setuid(uid)
        }}
      />
      <ForgetPasswordModal
        // isOpen={modal_forget_password}
        isOpen={props.showForgetPassword}
        toggle={() => {
          tog_forget_password()
        }}
        onEmailSend={() => {
          // setmodal_forget_password(false)
          tog_forget_password()
          tog_reset_password()
        }}
      />
      <ResetPasswordModal
        // isOpen={modal_reset_password}
        isOpen={props.showResetPassword}
        toggle={() => {
          tog_reset_password()
        }}
        onLogin={() => {
          // setmodal_reset_password(false)
          tog_reset_password()
          tog_login()
        }}
      />
      <SignupModal
        // isOpen={modal_signup}
        isOpen={props.showJoinNow}
        toggle={() => {
          tog_signup()
        }}
        onLogin={() => {
          // setmodal_signup(false)
          tog_signup()
          tog_login()
        }}
        onVerify={() => {
          // setmodal_signup(false)
          tog_signup()
          tog_signup_verification()
        }}
      />
      <SignupVerificationModal
        // isOpen={modal_signup_verification}
        isOpen={props.showSignupVerification}
        toggle={() => {
          tog_signup_verification()
        }}
        onLogin={() => {
          // setmodal_signup_verification(false)
          tog_signup_verification()
          tog_referral()
        }}
        onSignup={() => {
          // setmodal_signup_verification(false)
          tog_signup_verification()
          tog_signup()
        }}
      />
      <ReferralModal
        // isOpen={modal_referral}
        isOpen={props.showReferral}
        toggle={() => {
          tog_referral()
        }}
        onSkip={() => {
          // setmodal_referral(false)
          tog_referral()
          tog_login()
        }}
        onSubmit={() => {
          tog_referral()
          // setmodal_referral(false)
          tog_login()
        }}
      />
      <OnboardModal
        // isOpen={modal_onboard}
        isOpen={props.showOnboard}
        toggle={() => {
          tog_onboard()
        }}
        onLogout={() => {
          tog_onboard()
          logout()
        }}
      />
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  showLogin: PropTypes.any,
  showJoinNow: PropTypes.any,
  showForgetPassword: PropTypes.any,
  showResetPassword: PropTypes.any,
  showSignupVerification: PropTypes.any,
  showReferral: PropTypes.any,
  showOnboard: PropTypes.any,
  history: PropTypes.object,
  logoutUser: PropTypes.func,
  loginUser: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    leftMenu,
    showRightSidebar,
    showLogin,
    showJoinNow,
    showForgetPassword,
    showResetPassword,
    showSignupVerification,
    showReferral,
    showOnboard,
  } = state.Layout
  const { user } = state.Login
  return {
    leftMenu,
    showRightSidebar,
    showLogin,
    showJoinNow,
    showForgetPassword,
    showResetPassword,
    showSignupVerification,
    showReferral,
    showOnboard,
    user,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    toggleLeftmenu,
    showRightSidebarAction,
    showLoginAction,
    showJoinNowAction,
    showForgetPasswordAction,
    showResetPasswordAction,
    showSignupVerificationAction,
    showReferralAction,
    showOnboardAction,
    logoutUser,
    loginUser,
  })(withTranslation()(Navbar))
)
