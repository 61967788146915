//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

//CROWDCARE
export const CC_GET_PROJECTS =
  "https://crowdcare.beuniq.my/api/get_projects_table"
export const CC_GET_DONORS = "https://crowdcare.beuniq.my/api/get_all_donors"
export const CC_GET_USER = "https://crowdcare.beuniq.my/api/get_users"
export const CC_GET_USER_DETAILS =
  "https://crowdcare.beuniq.my/api/get_user_details"
export const CC_POST_UPDATE_USER_DETAILS =
  "https://crowdcare.beuniq.my/api/update_user_details"
export const CC_POST_CREATE_USER = "https://crowdcare.beuniq.my/api/create_user"
export const CC_GET_DONATION_HISTORY_WITH_ID =
  "https://crowdcare.beuniq.my/api/get_donation_history"
export const CC_GET_APPLICATIONS =
  "https://crowdcare.beuniq.my/api/get_project_applications"
export const CC_GET_APPLICATION_DETAILS =
  "https://crowdcare.beuniq.my/api/get_project_application_details"
export const CC_POST_UPDATE_APPLICATION =
  "https://crowdcare.beuniq.my/api/update_project_application"
export const CC_POST_APPLICANT =
  "https://crowdcare.beuniq.my/api/submit_application"
export const CC_POST_UPDATE_PROJECT =
  "https://crowdcare.beuniq.my/api/update_project"
export const CC_POST_REJECT_APPLICATION =
  "https://crowdcare.beuniq.my/api/reject_project_application"
export const CC_POST_UPLOAD_PROJECT_IMAGE =
  "https://crowdcare.beuniq.my/api/upload_project_image"
export const CC_POST_DELETE_PROJECT_IMAGE =
  "https://crowdcare.beuniq.my/api/delete_project_image"
export const CC_GET_DONATION_DETAILS_WITH_ID =
  "https://crowdcare.beuniq.my/api/get_donation_history_details"
export const CC_POST_UPDATE_PROJECT_UPDATES =
  "https://crowdcare.beuniq.my/api/update_project_updates"
export const CC_POST_UNPUBLISH_PROJECT =
  "https://crowdcare.beuniq.my/api/unpublish_project"
export const CC_GET_AUDIT_LOG = "https://crowdcare.beuniq.my/api/get_audit_log"
export const CC_LOGIN = "https://crowdcare.beuniq.my/api/backend_login"
export const CC_GET_DASHBOARD = "https://crowdcare.beuniq.my/api/get_dashboard"

// G5
export const G5_GET_RECOGNITION =
  "https://g5.beuniq.my/api/get_recognition_list"
export const G5_DELETE_RECOGNITION =
  "https://g5.beuniq.my/api/delete_recognition"
export const G5_UPDATE_RECOGNITION =
  "https://g5.beuniq.my/api/update_recognition"
export const G5_POST_COMMENT = "https://g5.beuniq.my/api/insert_comment"
export const G5_DELETE_COMMENT = "https://g5.beuniq.my/api/delete_comment"
export const G5_POST_RECOGNITION = "https://g5.beuniq.my/api/insert_recognition"
export const G5_TOGGLE_REACTION = "https://g5.beuniq.my/api/toggle_reaction"
export const G5_GET_COMPANY_USER =
  "https://g5.beuniq.my/api/get_companyuserreceiver_list"
export const G5_GET_COMPANY_VALUE =
  "https://g5.beuniq.my/api/get_companyvalue_list"
export const G5_LOGIN = "https://g5.beuniq.my/api/login"
export const G5_REGISTRATION = "https://g5.beuniq.my/api/registration"
export const G5_GET_USERDETAIL = "https://g5.beuniq.my/api/get_user_details"
export const G5_GET_ALLOWANCESETTING =
  "https://g5.beuniq.my/api/get_allowance_setting"
export const G5_POST_ALLOWANCESETTING =
  "https://g5.beuniq.my/api/update_allowance_setting"
export const G5_GET_SETTING = "https://g5.beuniq.my/api/get_setting"
export const G5_POST_SETTING = "https://g5.beuniq.my/api/update_setting"
export const G5_POST_ADDVALUE = "https://g5.beuniq.my/api/add_companyvalue"
export const G5_POST_UPDATEVALUE =
  "https://g5.beuniq.my/api/update_companyvalue"
export const G5_POST_DELETEVALUE =
  "https://g5.beuniq.my/api/delete_companyvalue"
export const G5_GET_USER_TABLEDATA =
  "https://g5.beuniq.my/api/get_companyuser_list"
export const G5_GET_USER_EARNINGDATA =
  "https://g5.beuniq.my/api/get_earning_list"
export const G5_POST_IMPORTUSER = "https://g5.beuniq.my/api/import_user"
export const G5_POST_ADDUSER = "https://g5.beuniq.my/api/add_user"
export const G5_POST_UPDATEUSER = "https://g5.beuniq.my/api/update_user"
export const G5_POST_DELETEUSER = "https://g5.beuniq.my/api/delete_user"
export const G5_GET_COMPANY_INVITATION =
  "https://g5.beuniq.my/api/get_company_invitation"
export const G5_POST_CHECK_USER_INVITED =
  "https://g5.beuniq.my/api/check_user_invited"
export const G5_POST_UPDATE_USER_DETAILS =
  "https://g5.beuniq.my/api/update_user_details"
export const G5_POST_STRIPE_INTENT = "https://g5.beuniq.my/api/stripe_intent"
export const G5_GET_CHALLENGE_TABLE =
  "https://g5.beuniq.my/api/get_challenge_table"
export const G5_POST_CREATE_CHALLENGE =
  "https://g5.beuniq.my/api/create_challenge"
export const G5_POST_EDIT_CHALLENGE = "https://g5.beuniq.my/api/edit_challenge"
export const G5_GET_CHALLENGE_USER =
  "https://g5.beuniq.my/api/get_challenge_with_user"
export const G5_GET_CHALLENGE_USER_DETAILS =
  "https://g5.beuniq.my/api/get_challenge_details_with_user"
export const G5_GET_CHALLENGE_LEADERBOARD =
  "https://g5.beuniq.my/api/get_leaderboard_with_user"
export const G5_POST_DELETE_CHALLENGE =
  "https://g5.beuniq.my/api/delete_challenge"
export const G5_GET_ANALYTICS = "https://g5.beuniq.my/api/get_user_analytics"

//hapivity
const HAPIVITY_HOST = "https://store.hapivity.com"
// const HAPIVITY_HOST = "http://188.166.246.90"

export const HAPIVITY_GET_BRAINTREE_SETTING =
  HAPIVITY_HOST + "/api/init_braintree"
export const HAPIVITY_POST_BRAINTREE_PAYMENT =
  HAPIVITY_HOST + "/api/braintree_payment"
export const HAPIVITY_POST_CREATE_ORDER = HAPIVITY_HOST + "/api/create_order"
export const HAPIVITY_GET_ORDER = HAPIVITY_HOST + "/api/get_order"
export const HAPIVITY_GET_SHOP_PRODUCT_LIST =
  HAPIVITY_HOST + "/api/get_product_list_db"
export const HAPIVITY_GET_SHOP_PRODUCT_DETAILS =
  HAPIVITY_HOST + "/api/get_product_details_db"
export const HAPIVITY_GET_USER_ADDRESS_BOOK =
  HAPIVITY_HOST + "/api/get_user_address_book"
export const HAPIVITY_LOGIN = HAPIVITY_HOST + "/api/login_with_email"
export const HAPIVITY_LOGIN_TOKEN = HAPIVITY_HOST + "/api/login_with_token"
export const HAPIVITY_REQUESTOTP = HAPIVITY_HOST + "/api/request_otp"
export const HAPIVITY_SIGNUP = HAPIVITY_HOST + "/api/registration"
export const HAPIVITY_ADD_REFERRAL = HAPIVITY_HOST + "/api/add_referral"
export const HAPIVITY_RESET_PASSWORD = HAPIVITY_HOST + "/api/reset_password"
export const HAPIVITY_ONBOARD = HAPIVITY_HOST + "/api/onboard_web"
export const HAPIVITY_HOME_PAGE = HAPIVITY_HOST + "/api/get_home_page"
export const HAPIVITY_GET_ORDER_LIST =
  HAPIVITY_HOST + "/api/get_order_list_admin"
export const HAPIVITY_GET_ORDER_LIST_USER =
  HAPIVITY_HOST + "/api/get_order_list"
export const HAPIVITY_GET_MY_DETAILS = HAPIVITY_HOST + "/api/get_my_details"
export const HAPIVITY_SUPPORTED_COUNTRY =
  HAPIVITY_HOST + "/api/supported_country"
export const HAPIVITY_CONTEST_TERM = HAPIVITY_HOST + "/api/get_contest_term"
export const HAPIVITY_APPLY_PROMO = HAPIVITY_HOST + "/api/apply_promo"

export const HAPIVITY_STRIPE_INTENT = HAPIVITY_HOST + "/api/stripe_intent"
export const HAPIVITY_STRIPE_PAYMENT_SUCCESS =
  HAPIVITY_HOST + "/api/stripe_payment_success"

export const HAPIVITY_GET_ADMIN_SHOP_LIST =
  HAPIVITY_HOST + "/api/get_admin_shop_list"
export const HAPIVITY_GET_ADMIN_PACKAGE_LIST =
  HAPIVITY_HOST + "/api/get_admin_package_list"
export const HAPIVITY_EDIT_SHOP_PACKAGE =
  HAPIVITY_HOST + "/api/edit_shop_package"
export const HAPIVITY_EDIT_SHOP_PHOTO = HAPIVITY_HOST + "/api/edit_shop_photo"
export const HAPIVITY_GET_ADMIN_ENTITLEMENT_LIST =
  HAPIVITY_HOST + "/api/get_admin_entitlement_list"
export const HAPIVITY_EDIT_PACKAGE_ENTITLEMENT =
  HAPIVITY_HOST + "/api/edit_package_entitlement"
export const HAPIVITY_GET_ADMIN_SHIPPING_LIST =
  HAPIVITY_HOST + "/api/get_admin_shipping_list"
export const HAPIVITY_EDIT_SHIPPING = HAPIVITY_HOST + "/api/edit_shipping"
export const HAPIVITY_EDIT_SHIPPING_PRICE =
  HAPIVITY_HOST + "/api/edit_shipping_price"
export const HAPIVITY_EDIT_PACKAGE_SHIPPING =
  HAPIVITY_HOST + "/api/edit_package_shipping"
export const HAPIVITY_GET_ADMIN_PRICE_LIST =
  HAPIVITY_HOST + "/api/get_admin_price_list"
export const HAPIVITY_EDIT_PACKAGE_PRICE =
  HAPIVITY_HOST + "/api/edit_package_price"
export const HAPIVITY_EDIT_PRICE = HAPIVITY_HOST + "/api/edit_price"
export const HAPIVITY_GET_ADMIN_PHOTO_LIST =
  HAPIVITY_HOST + "/api/get_admin_photo_list"
export const HAPIVITY_EDIT_PHOTO = HAPIVITY_HOST + "/api/edit_photo"
export const HAPIVITY_EDIT_PHOTO_COUNTRY =
  HAPIVITY_HOST + "/api/edit_photo_country"
export const HAPIVITY_GET_ADMIN_DASHBOARD = HAPIVITY_HOST + "/api/get_dashboard"
export const HAPIVITY_UPDATE_SHIPPING_STATUS =
  HAPIVITY_HOST + "/api/update_shipping_status"
export const HAPIVITY_UPDATE_COMMISSION =
  HAPIVITY_HOST + "/api/update_commission"
export const HAPIVITY_GET_PROMO_CODE_ADMIN =
  HAPIVITY_HOST + "/api/get_promo_code"
export const HAPIVITY_ADD_PROMO_CODE_ADMIN =
  HAPIVITY_HOST + "/api/add_promo_code"
export const HAPIVITY_EDIT_PROMO_CODE_ADMIN =
  HAPIVITY_HOST + "/api/edit_promo_code"
export const HAPIVITY_DELETE_PROMO_CODE_ADMIN =
  HAPIVITY_HOST + "/api/delete_promo_code"
export const HAPIVITY_GET_SHIPPING_BY_COUNTRY_LIST =
  HAPIVITY_HOST + "/api/get_shipping_by_country_list"
export const HAPIVITY_GET_ACTIVITY_BY_COUNTRY_ADMIN =
  HAPIVITY_HOST + "/api/get_activity_by_country"
export const HAPIVITY_UPDATE_PACKAGE_AVAILABILITY =
  HAPIVITY_HOST + "/api/toggle_package_availability"
export const HAPIVITY_ASSIGN_BATCH = HAPIVITY_HOST + "/api/assign_batch"
export const HAPIVITY_GET_NEW_BATCH_ORDER =
  HAPIVITY_HOST + "/api/get_new_batch_order"
export const HAPIVITY_GET_BATCH_LIST = HAPIVITY_HOST + "/api/get_batch_list"
export const HAPIVITY_EXPORT_BATCH_CSV = HAPIVITY_HOST + "/api/export_batch_csv"
export const HAPIVITY_UPDATE_BATCH_RECEIPT =
  HAPIVITY_HOST + "/api/update_batch_receipt"
export const HAPIVITY_READ_STRIPE_CSV = HAPIVITY_HOST + "/api/read_stripe_csv"
export const HAPIVITY_GET_COMMISION_INVOICE =
  HAPIVITY_HOST + "/api/get_commission_invoice"
export const HAPIVITY_GET_COMMISSION_LIST =
  HAPIVITY_HOST + "/api/get_commission_list"
export const HAPIVITY_GET_SHIPPING_FEES_LIST =
  HAPIVITY_HOST + "/api/get_shipping_fees_list"
export const HAPIVITY_GET_SHIPPING_INVOICE =
  HAPIVITY_HOST + "/api/get_shipping_invoice"
export const HAPIVITY_REFUND_ORDER = HAPIVITY_HOST + "/api/refund_order"
