import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Modal,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import images from "assets/images"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const ReferralModal = props => {
  const [referralCode, setreferral_code] = useState(null)
  const [uid, setUid] = useState(null)

  const updateField = e => {
    setreferral_code(e.target.value)
  }

  useEffect(() => {
    if (localStorage.getItem("hId")) {
      setUid(localStorage.getItem("hId"))
    }
  }, [])

  function handleValidSubmit(event, values) {
    console.log(values)
    if (values.referral == "") {
      props.onSkip()
    } else {
      submitReferral(values)
    }
  }

  const submitReferral = async values => {
    let formdata = new FormData()
    formdata.append("referrer", values.referral)
    formdata.append("id", uid)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_ADD_REFERRAL, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      if (postresponse.success) {
        props.onSubmit()
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        scrollable={true}
        id="staticBackdrop"
        backdrop={"static"}
        // className="modal-dialog-centered"
        // style={{ backgroundColor: "#FBFBFB" }}
      >
        {/* <div
          className="modal-header"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="btn btn-link text-primary px-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="bx bx-chevron-left font-size-16 align-middle"></i>
            <span aria-hidden="true" className="font-weight-bold font-size-14">
              {" Back to Hapivity"}
            </span>
          </button>
        </div> */}
        <Row className="modal-body" style={{ backgroundColor: "#FBFBFB" }}>
          <Col>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <Row className="justify-content-center">
                <Col className="align-self-center">
                  <Row className="justify-content-center mb-4">
                    <Col>
                      <Card
                        className="shadow-none p-3"
                        style={{ background: "#F3F4F8", borderRadius: "16px" }}
                      >
                        <CardTitle
                          className="text-center font-weight-extrabold font-size-20"
                          style={{ color: "#1F2933" }}
                        >
                          {"Successful!"}
                        </CardTitle>
                        <CardSubtitle className="text-center font-weight-semibold font-size-16 text-secondary">
                          {
                            "Your account has been created successfully. If you have a referral code, enter it below."
                          }
                        </CardSubtitle>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                      <img
                        src={asset.logoReferral}
                        srcSet={`${asset.logoReferral} 300w, ${asset.logoReferralx2} 768w, ${asset.logoReferralx3} 1280w`}
                        alt=""
                        style={{ maxWidth: "164px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <AvField
                      name="referral"
                      // label="Email"
                      value={referralCode}
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="Enter a referral code"
                      type="text"
                      // required
                      onChange={updateField}
                      validate={{
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Referral code must be more than 6 characters",
                        },
                      }}
                      style={{
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col className="w-100">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        onClick={
                          referralCode &&
                          referralCode != "" &&
                          referralCode.length >= 6 &&
                          props.onSubmit
                        }
                      >
                        {referralCode &&
                        referralCode != "" &&
                        referralCode.length >= 6
                          ? "Continue"
                          : "Skip"}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

ReferralModal.propTypes = {
  setting: PropTypes.object,
}

export default ReferralModal
