import React from "react"
import { Card, Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

import { asset } from "helpers/asset_helper"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true} className="py-3">
          <hr className="mb-4" />
          <Row>
            {/* <Col lg="2" md="3" className="">
              <span className="font-size-16 font-weight-black align-middle text-primary">
                {"CUSTOMER CARE"}
              </span>
              <Row className="mb-3 mt-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to=""
                    onClick={() => {
                      window.open(
                        "https://help.hapivity.com/category/orders-payment",
                        "_blank"
                      )
                      return null
                    }}
                  >
                    {"Orders & Payment"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to=""
                    onClick={() => {
                      window.open(
                        "https://help.hapivity.com/category/shipping-tracking",
                        "_blank"
                      )
                      return null
                    }}
                  >
                    {"Shipping & Tracking"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to=""
                    onClick={() => {
                      window.open(
                        "https://help.hapivity.com/category/return-exchange-refund",
                        "_blank"
                      )
                      return null
                    }}
                  >
                    {"Return & Exchanges"}
                  </Link>
                </Col>
              </Row>
              
              <Row className="mb-3 mt-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/terms-and-conditions"
                  >
                    {"Terms & Conditions"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/privacy-policy"
                  >
                    {"Privacy Policy"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/cookie-policy"
                  >
                    {"Cookies Policy"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/disclaimer"
                  >
                    {"Disclaimer"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/data-deletion"
                  >
                    {"Data Deletion"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/eula"
                  >
                    {"EULA"}
                  </Link>
                </Col>
              </Row>
            </Col> */}
            <Col lg="2" md="3" className="">
              <span className="font-size-16 font-weight-black align-middle text-primary">
                {"INFORMATION"}
              </span>
              <Row className="mt-3 mb-3">
                <Link
                  className="font-size-16 font-weight-bold align-middle text-secondary"
                  to="/about"
                >
                  {"Our Story"}
                </Link>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/terms-and-conditions"
                  >
                    {"Terms & Conditions"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/privacy-policy"
                  >
                    {"Privacy Policy"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/cookie-policy"
                  >
                    {"Cookies Policy"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/disclaimer"
                  >
                    {"Disclaimer"}
                  </Link>
                </Col>
              </Row>
              {/*
              <Row className="mb-3">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/data-deletion"
                  >
                    {"Data Deletion"}
                  </Link>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Link
                    className="font-size-16 font-weight-bold align-middle text-secondary"
                    to="/eula"
                  >
                    {"EULA"}
                  </Link>
                </Col>
              </Row>
              */}
            </Col>
            <Col lg="4" md="6">
              <span className="font-size-16 font-weight-black align-middle text-primary">
                {"DOWNLOAD OUR APPS TODAY"}
              </span>
              <Row className="mt-3 mb-3">
                <Col lg="4" md="3" sm="3" xs="4" className="p-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-1 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <a
                        href={
                          "https://apps.apple.com/app/hapivity/id1589719031"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={asset.appstoreDownload}
                          srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            // maxWidth: "100%",
                            width: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </a>
                    </button>
                  </Card>
                </Col>
                <Col lg="4" md="3" sm="3" xs="4" className="p-1 px-lg-2">
                  <Card className="shadow-none bg-transparent">
                    <button
                      className="btn bg-white px-1 px-lg-2 px-xl-3"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                      }}
                    >
                      <a
                        href={
                          "https://play.google.com/store/apps/details?id=com.hapivity"
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={asset.googleDownload}
                          srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                          alt=""
                          style={{
                            // height: "100%",
                            width: "100%",
                            aspectRatio: "43/10",
                          }}
                          className="me-1"
                        />
                      </a>
                    </button>
                  </Card>
                </Col>
              </Row>
              <span className="font-size-16 font-weight-black align-middle text-primary">
                {"FOLLOW US"}
              </span>
              <Row className="mt-3 mb-3">
                <Col xs={"auto"} className="pe-0">
                  <img
                    className="font-size-16 font-weight-extrabold text-uppercase"
                    src={asset.iconInstagram}
                    srcSet={`${asset.iconInstagram} 300w, ${asset.iconInstagramx2} 768w, ${asset.iconInstagramx3} 1280w`}
                    alt=""
                    style={{ width: "48px", height: "48px", cursor: "pointer" }}
                    onClick={() => {
                      window.open("https://instagram.com/hapivity", "_blank")
                      return null
                    }}
                  />
                </Col>
                <Col xs={"auto"}>
                  <img
                    className="font-size-16 font-weight-extrabold text-uppercase"
                    src={asset.iconFacebook}
                    srcSet={`${asset.iconFacebook} 300w, ${asset.iconFacebookx2} 768w, ${asset.iconFacebookx3} 1280w`}
                    alt=""
                    style={{ width: "48px", height: "48px", cursor: "pointer" }}
                    onClick={() => {
                      window.open("https://facebook.com/hapivity", "_blank")
                      return null
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/*
          <Row className="mb-5">
            <Col className="align-items-center d-flex">
              <span
                className="font-size-16 font-weight-extrabold text-uppercase align-middle"
                style={{ color: "#8F9BB3" }}
              >
                {"Follow us on"}
              </span>
            </Col>
            <Col xs={"auto"} className="pe-0">
              <img
                className="font-size-16 font-weight-extrabold text-uppercase"
                src={asset.iconInstagram}
                srcSet={`${asset.iconInstagram} 300w, ${asset.iconInstagramx2} 768w, ${asset.iconInstagramx3} 1280w`}
                alt=""
                style={{ width: "48px", height: "48px" }}
              />
            </Col>
            <Col xs={"auto"}>
              <img
                className="font-size-16 font-weight-extrabold text-uppercase"
                src={asset.iconFacebook}
                srcSet={`${asset.iconFacebook} 300w, ${asset.iconFacebookx2} 768w, ${asset.iconFacebookx3} 1280w`}
                alt=""
                style={{ width: "48px", height: "48px" }}
              />
            </Col>
          </Row>
          */}
          <Row className="mb-3">
            <Col md={6}>
              Copyright {new Date().getFullYear()} © Hapivity. All rights
              reserved.
            </Col>
            {/* <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Themesbrand
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
