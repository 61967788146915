import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"
import images from "assets/images"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const ResetPasswordModal = props => {
  const [form, setForm] = useState(null)
  const [otp, setOTP] = useState(null)
  const [forgetPasswordError, setforgetpassword_error] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
    if (localStorage.getItem("hfForm")) {
      setForm(JSON.parse(localStorage.getItem("hfForm")))
    }
    if (localStorage.getItem("hfOTP")) {
      setOTP(JSON.parse(localStorage.getItem("hfOTP")))
    }
  }, [props.isOpen])

  function handleValidSubmit(event, values) {
    setforgetpassword_error(null)
    console.log(values)
    setLoading(true)
    if (values.otp == otp) {
      resetPassword(values)
    } else {
      setforgetpassword_error("Invalid OTP")
      setLoading(false)
    }
    // resetPassword(values)
    // sendOTP(values)
    // login(values)
    // props.loginUser(values, props.history)
  }

  const resetPassword = async values => {
    let formdata = new FormData()
    formdata.append("email", form.email)
    formdata.append("password", values.password)
    formdata.append("otp", values.otp)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_RESET_PASSWORD, requestOptions)
    const postresponse = await response.json()

    setLoading(false)
    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.removeItem("hfOTP")
        localStorage.removeItem("hfForm")

        props.onLogin()
      } else {
        setforgetpassword_error(postresponse.message)
      }
    }
  }

  const [otpCountdown, setOtpCountdown] = useState(0)
  useEffect(() => {
    if (otpCountdown > 0) {
      setTimeout(() => setOtpCountdown(otpCountdown - 1), 1000)
    }
  }, [otpCountdown])

  const sendOTP = async () => {
    setOtpCountdown(30)
    let formdata = new FormData()
    formdata.append("email", form.email)
    formdata.append("action", "forgot_password")

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_REQUESTOTP, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    setLoading(false)
    if (response.status == 200) {
      if (postresponse.success) {
        localStorage.setItem("hfOTP", postresponse.data.otp)
      } else {
        setforgetpassword_error(postresponse.message)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div
          className="modal-header"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="btn btn-link text-primary px-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="bx bx-chevron-left font-size-16 align-middle"></i>
            <span aria-hidden="true" className="font-weight-bold font-size-14">
              {" Back to Hapivity"}
            </span>
          </button>
        </div>
        <Row className="modal-body" style={{ backgroundColor: "#FBFBFB" }}>
          <Col>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <Row className="justify-content-center">
                <Col md={6} lg={4} className="align-self-center">
                  <Row className="justify-content-center mb-4">
                    <Col xs="auto">
                      <img
                        src={asset.iconLetter}
                        srcSet={`${asset.iconLetter} 300w, ${asset.iconLetterx2} 768w, ${asset.iconLetterx3} 1280w`}
                        alt=""
                        style={{ maxWidth: "72px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                      <span className="font-weight-extrabold font-size-20">
                        {"Verification required"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col xs="auto" className="text-center">
                      <span className="font-weight-semibold font-size-14">
                        {"To continue, complete this verification step. We’ve sent the One Time Password (OTP) to the email " +
                          (form && form.email) +
                          ". Please enter in below."}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center mb-0"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                        backgroundColor: "#F3F4F8",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconEmail}
                            srcSet={`${asset.iconEmail} 300w, ${asset.iconEmailx2} 768w, ${asset.iconEmailx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="email"
                            // label="Email"
                            value="any@email.com"
                            className="form-control font-weight-semibold font-size-16 text-secondary"
                            placeholder="Email"
                            type="email"
                            // required
                            disabled
                            style={{
                              border: "none",
                              backgroundColor: "#F3F4F8",
                            }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="email"
                      // label="Email"
                      value={form && form.email}
                      className="form-control font-weight-semibold font-size-16 mb-3 text-secondary"
                      placeholder="Email"
                      type="email"
                      // required
                      disabled
                      validate={{
                        email: {
                          value: true,
                          errorMessage: "Please enter a valid email",
                        },
                        required: {
                          value: true,
                          errorMessage: "Please enter email",
                        },
                      }}
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center mb-0"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconPassword}
                            srcSet={`${asset.iconPassword} 300w, ${asset.iconPasswordx2} 768w, ${asset.iconPasswordx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="password"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="New Password"
                            type="password"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="password"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="New Password"
                      type="password"
                      // required
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter password",
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Your password must be more than 6 characters",
                        },
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center">
                    {/* <Card
                      className="shadow-none align-self-center mb-0"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconPassword}
                            srcSet={`${asset.iconPassword} 300w, ${asset.iconPasswordx2} 768w, ${asset.iconPasswordx3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="retype_password"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="Retype New Password"
                            type="password"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="password_retype"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="Retype New Password"
                      type="password"
                      // required
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter password",
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Your password must be more than 6 characters",
                        },
                        match: {
                          value: "password",
                          errorMessage:
                            "Your retype password must be match with password",
                        },
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center mb-4">
                    {/* <Card
                      className="shadow-none align-self-center mb-0"
                      style={{
                        border: "2px solid #F3F4F8",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                    >
                      <Row className="h-100">
                        <Col xs="auto align-self-center pe-0">
                          <img
                            src={asset.iconMessage}
                            srcSet={`${asset.iconMessage} 300w, ${asset.iconMessagex2} 768w, ${asset.iconMessagex3} 1280w`}
                            alt=""
                            style={{
                              width: "24px",
                            }}
                          />
                        </Col>
                        <Col className="align-self-center ps-0">
                          <AvField
                            name="otp"
                            // label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control font-weight-semibold font-size-16"
                            placeholder="OTP"
                            type="number"
                            // required
                            style={{ border: "none" }}
                          />
                        </Col>
                      </Row>
                    </Card> */}
                    <AvField
                      name="otp"
                      // label="Email"
                      // value="admin@themesbrand.com"
                      className="form-control font-weight-semibold font-size-16 mb-3"
                      placeholder="OTP"
                      type="text"
                      // required
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        height: "56px",
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter otp",
                        },
                        minLength: {
                          value: 6,
                          errorMessage:
                            "Your OTP must be more than 6 characters",
                        },
                      }}
                    />
                  </Row>
                  <Row className="justify-content-center mb-3">
                    <Col className="w-100">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        type="submit"
                        // onClick={props.onLogin}
                      >
                        {"Save changes and sign-in"}
                      </button>
                    </Col>
                  </Row>
                  {forgetPasswordError && (
                    <Row className="mb-3">
                      <Col>
                        <Alert
                          color="danger"
                          role="alert"
                          className="border-radius-8 font-size-14 font-weight-semibold align-middle"
                        >
                          {forgetPasswordError}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <Row className="justify-content-center mb-3">
                    <Col className="w-100">
                      <button
                        className="btn btn-link btn-block waves-effect waves-light w-100 font-size-16"
                        style={{
                          borderRadius: "16px",
                          height: "56px",
                          fontWeight: 700,
                        }}
                        onClick={sendOTP}
                        disabled={otpCountdown != 0}
                      >
                        {"Resend OTP" +
                          (otpCountdown != 0 ? " (" + otpCountdown + ") " : "")}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  )
}

ResetPasswordModal.propTypes = {
  setting: PropTypes.object,
}

export default ResetPasswordModal
