import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "store/auth/login/actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
} from "store/auth/login/actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "helpers/fakebackend_helper"
import * as url from "helpers/url_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // const response = yield call(postFakeLogin, {
      //   email: user.email,
      //   password: user.password,
      // })
      // console.log(JSON.stringify(response))
      // localStorage.setItem("authUser", JSON.stringify(response))
      // yield put(loginSuccess(response))

      const submitForm = async () => {
        console.log("logging in")
        const form = { email: user.email, password: user.password }
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(form),
        }
        console.log(JSON.stringify(form))

        const response = await fetch(url.HAPIVITY_LOGIN, requestOptions)
        const postresponse = await response.json()

        console.log(JSON.stringify(postresponse))
        return { status: response.status, postresponse: postresponse }
      }

      const submitFormRefresh = async () => {
        console.log("logging in")
        const form = { uid: user.uid, token: user.token }
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(form),
        }
        console.log(JSON.stringify(form))

        const response = await fetch(url.HAPIVITY_LOGIN_TOKEN, requestOptions)
        const postresponse = await response.json()

        console.log(JSON.stringify(postresponse))
        return { status: response.status, postresponse: postresponse }
      }

      if (user.email) {
        console.log("submit normal")
        const response = yield call(submitForm)
        console.log(response.status)
        if (response.status == 200 && response.postresponse.success) {
          console.log("in")
          localStorage.setItem(
            "hUser",
            JSON.stringify(response.postresponse.data)
          )
          yield put(loginSuccess(JSON.stringify(response.postresponse.data)))
          console.log(response.postresponse.data)
          // history.push("/dashboard")
        } else {
          // put(apiError(postresponse.error))
          console.log(response.postresponse.message)
          yield put(
            apiError({
              error: response.postresponse.message,
              time: Date().toString,
            })
          )
        }
      } else {
        console.log("submit refresh")
        const response = yield call(submitFormRefresh)
        console.log(response.status)
        if (response.status == 200 && response.postresponse.success) {
          console.log("in")
          localStorage.setItem(
            "hUser",
            JSON.stringify(response.postresponse.data)
          )
          yield put(loginSuccess(JSON.stringify(response.postresponse.data)))
          console.log(response.postresponse.data)
          // history.push("/dashboard")
        } else {
          // put(apiError(postresponse.error))
          console.log(response.postresponse.message)
          yield put(
            apiError({
              error: response.postresponse.message,
              time: Date().toString,
            })
          )
        }
      }
      // localStorage.setItem("authUser", postresponse.data)
      // yield put(loginSuccess(postresponse.data))
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    console.log("logging out")
    localStorage.removeItem("hUser")
    localStorage.removeItem("hAuth")
    localStorage.removeItem("hId")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    yield put(logoutUserSuccess())
    // history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
