import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FormGroup, Row, Col, ModalFooter } from "reactstrap"

import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
  showLoginAction,
  showJoinNowAction,
} from "../../store/actions"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link, useHistory } from "react-router-dom"

//hapivity asset
import { asset } from "helpers/asset_helper"

import LoginModal from "pages/Hapivity/LoginModal"
import SignupModal from "pages/Hapivity/SignupModal"
import SignupVerificationModal from "pages/Hapivity/SignupVerificationModal"
import ForgetPasswordModal from "pages/Hapivity/ForgetPasswordModal"
import ResetPasswordModal from "pages/Hapivity/ResetPasswordModal"
import ReferralModal from "pages/Hapivity/ReferralModal"
import OnboardModal from "pages/Hapivity/OnboardModal"

import "../../components/CommonForBoth/rightbar.scss"
//Import images
import layout1 from "../../assets/images/layouts/layout-1.jpg"
import layout2 from "../../assets/images/layouts/layout-2.jpg"
import layout3 from "../../assets/images/layouts/layout-3.jpg"

import * as url from "helpers/url_helper"
import { logoutUser } from "store/actions"

const RightSidebar = props => {
  const history = useHistory()

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("rightbar")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      // console.log(props.pathname + " : " + items[i].pathname)
      if (props.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
    if (localStorage.getItem("hAuth")) {
      setremember_token(localStorage.getItem("hAuth"))
    }
    if (localStorage.getItem("hId")) {
      setuid(localStorage.getItem("hId"))
    }
  }, [])

  function activateParentDropdown(item) {
    console.log(item)
    item.classList.add("active")

    return false
  }

  //modal login
  const [modal_login, setmodal_login] = useState(false)
  const [modal_signup, setmodal_signup] = useState(false)
  const [modal_forget_password, setmodal_forget_password] = useState(false)
  const [modal_reset_password, setmodal_reset_password] = useState(false)
  const [modal_signup_verification, setmodal_signup_verification] = useState(
    false
  )
  const [modal_referral, setmodal_referral] = useState(false)
  const [modal_onboard, setmodal_onboard] = useState(false)

  function tog_login() {
    // setmodal_login(!modal_login)
    props.showLoginAction(true)
    props.showRightSidebarAction(false)
    // removeBodyCss()
  }

  function tog_signup() {
    // setmodal_signup(!modal_signup)
    props.showJoinNowAction(true)
    props.showRightSidebarAction(false)
    // removeBodyCss()
  }

  // function tog_forget_password() {
  //   setmodal_forget_password(!modal_forget_password)
  //   removeBodyCss()
  // }

  // function tog_reset_password() {
  //   setmodal_reset_password(!modal_reset_password)
  //   removeBodyCss()
  // }

  // function tog_signup_verification() {
  //   setmodal_signup_verification(!modal_signup_verification)
  //   removeBodyCss()
  // }

  // function tog_referral() {
  //   setmodal_referral(!modal_referral)
  //   removeBodyCss()
  // }

  // function tog_onboard() {
  //   setmodal_onboard(!modal_onboard)
  //   setOnboard(false)
  //   removeBodyCss()
  // }

  // function removeBodyCss() {
  //   document.body.classList.add("no_padding")
  // }

  const [uid, setuid] = useState(null)
  const [remember_token, setremember_token] = useState(null)
  const [user, setuser] = useState(null)
  // const [onboard, setOnboard] = useState(false)

  // useEffect(() => {
  //   console.log("onboard", onboard)
  //   if (onboard) {
  //     tog_onboard()
  //   }
  // }, [onboard])

  useEffect(() => {
    if (remember_token && uid) {
      login(uid, remember_token)
    }
  }, [remember_token, uid])

  const login = async (uid, token) => {
    let formdata = new FormData()
    formdata.append("uid", uid)
    formdata.append("token", token)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_LOGIN_TOKEN, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      // console.log()
      if (postresponse.success) setuser(postresponse.data)
    }
  }

  useEffect(() => {
    if (user) localStorage.setItem("hUser", JSON.stringify(user))
  }, [user])

  function logout() {
    props.logoutUser(props.history)
    setuser(null)
    setremember_token(null)
    setuid(null)
    history.push("home")
  }

  return (
    <React.Fragment>
      {!modal_login &&
        !modal_forget_password &&
        !modal_reset_password &&
        !modal_signup &&
        !modal_signup_verification && (
          <div className="right-bar">
            <SimpleBar style={{ height: "900px", overflowX: "hidden" }}>
              <div data-simplebar className="h-100">
                <div className="rightbar-title px-3 py-4">
                  <Link
                    to="#"
                    onClick={e => {
                      e.preventDefault()
                      props.showRightSidebarAction(false)
                    }}
                    className="right-bar-toggle float-end"
                  >
                    <span aria-hidden="true">&times;</span>
                  </Link>
                  <h5 className="m-0">&nbsp;</h5>
                </div>

                {/* <hr className="my-0" /> */}

                <ul
                  className="navbar-nav font-weight-bold font-size-16 px-4"
                  id="rightbar"
                >
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/home"
                      onClick={e => {
                        props.showRightSidebarAction(false)
                      }}
                    >
                      {"Home"}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/store"
                      onClick={e => {
                        props.showRightSidebarAction(false)
                      }}
                    >
                      {"Store"}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/"
                      onClick={e => {
                        props.showRightSidebarAction(false)
                      }}
                    >
                      {"Features"}
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/about"
                      onClick={e => {
                        props.showRightSidebarAction(false)
                      }}
                    >
                      {"About"}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/help"
                      onClick={e => {
                        props.showRightSidebarAction(false)
                        // window.open("https://help.hapivity.com/", "_blank")
                        // return null
                      }}
                    >
                      {"Help"}
                    </Link>
                  </li>
                </ul>

                {!user && (
                  <Row className="px-4 mt-5">
                    <Col>
                      <span className="text-secondary font-weight-extrabold font-size-16">
                        {
                          "Sign up to experience the features packed Virtual Challenge"
                        }
                      </span>
                    </Col>
                  </Row>
                )}
                {!user && (
                  <Row className="px-4 mt-4">
                    <Col xs="auto">
                      <button
                        className="btn btn-link text-primary font-weight-extrabold font-size-14"
                        onClick={() => {
                          // props.showRightSidebarAction(false)
                          tog_login()
                        }}
                        style={{
                          border: "2px solid #FF3D00",
                          borderRadius: "14px",
                        }}
                      >
                        {"Sign in"}
                      </button>
                    </Col>

                    <Col xs="auto">
                      <button
                        className="btn btn-primary font-weight-extrabold font-size-14"
                        style={{ borderRadius: "14px" }}
                        onClick={() => {
                          // props.showRightSidebarAction(false)
                          tog_signup()
                        }}
                      >
                        {"Join now"}
                      </button>
                    </Col>
                  </Row>
                )}

                {/* <Row className="px-4 mt-5">
                  <Col xs="auto">
                    <button className="btn btn-link text-secondary font-weight-extrabold font-size-14 ps-0">
                      <img
                        src={asset.iconBagDark}
                        srcSet={`${asset.iconBagDark} 300w, ${asset.iconBagDarkx2} 768w, ${asset.iconBagDarkx3} 1280w`}
                        alt=""
                        height="24"
                        className="me-2"
                      />
                      {"Bag"}
                    </button>
                  </Col>
                </Row> */}

                {/* <Row className="px-4 mt-3">
                  <Col xs="auto">
                    <button className="btn btn-link text-secondary font-weight-extrabold font-size-14 ps-0">
                      <img
                        src={asset.iconHeart}
                        srcSet={`${asset.iconHeart} 300w, ${asset.iconHeartx2} 768w, ${asset.iconHeartx3} 1280w`}
                        alt=""
                        height="24"
                        className="me-2"
                      />
                      {"Favourite"}
                    </button>
                  </Col>
                </Row> */}

                {user && (
                  <>
                    <Row className="px-4 mt-3">
                      <Col xs="auto">
                        <span className="text-secondary font-weight-extrabold font-size-14">
                          {user && user.display_name}
                        </span>
                      </Col>
                    </Row>
                    <Row className="px-4">
                      <Col xs="auto">
                        <Link
                          to={{
                            pathname: "/dashboard",
                            dashboardSelect: "profile",
                          }}
                          onClick={e => {
                            props.showRightSidebarAction(false)
                          }}
                        >
                          <div
                            className="font-weight-bold font-size-14 text-secondary mt-2 mb-2"
                            style={{
                              marginLeft: "18px",
                            }}
                          >
                            My Profile
                          </div>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="px-4">
                      <Col xs="auto">
                        <Link
                          to={{
                            pathname: "/dashboard",
                            dashboardSelect: "order",
                          }}
                          onClick={e => {
                            props.showRightSidebarAction(false)
                          }}
                        >
                          <div
                            className="font-weight-bold font-size-14 text-secondary mb-2"
                            style={{
                              marginLeft: "18px",
                            }}
                          >
                            My Order
                          </div>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="px-4">
                      <Col xs="auto">
                        <Link
                          to={{
                            pathname: "/dashboard",
                            dashboardSelect: "address",
                          }}
                          onClick={e => {
                            props.showRightSidebarAction(false)
                          }}
                        >
                          <div
                            className="font-weight-bold font-size-14 text-secondary mb-1"
                            style={{
                              marginLeft: "18px",
                            }}
                          >
                            Address Book
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
                {user && (
                  <Row className="px-4">
                    <Col xs="auto">
                      <button
                        className="btn btn-link text-secondary font-weight-extrabold font-size-14 ps-0"
                        onClick={logout}
                      >
                        <img
                          src={asset.iconSignout}
                          srcSet={`${asset.iconSignout} 300w, ${asset.iconSignoutx2} 768w, ${asset.iconSignoutx3} 1280w`}
                          alt=""
                          height="24"
                          className="me-2"
                        />
                        {"Sign out"}
                      </button>
                    </Col>
                  </Row>
                )}
              </div>
            </SimpleBar>
          </div>
        )}
      {!modal_login &&
        !modal_forget_password &&
        !modal_reset_password &&
        !modal_signup &&
        !modal_signup_verification && <div className="rightbar-overlay" />}
      {/* <LoginModal
        isOpen={modal_login}
        toggle={() => {
          tog_login()
          props.showRightSidebarAction(false)
        }}
        onSignup={() => {
          setmodal_login(false)
          tog_signup()
        }}
        onForgetPassword={() => {
          setmodal_login(false)
          tog_forget_password()
        }}
        onLogin={(uid, token) => {
          setremember_token(token)
          setuid(uid)
        }}
      />
      <ForgetPasswordModal
        isOpen={modal_forget_password}
        toggle={() => {
          tog_forget_password()
          props.showRightSidebarAction(false)
        }}
        onEmailSend={() => {
          setmodal_forget_password(false)
          tog_reset_password()
        }}
      />
      <ResetPasswordModal
        isOpen={modal_reset_password}
        toggle={() => {
          tog_reset_password()
          props.showRightSidebarAction(false)
        }}
        onLogin={() => {
          setmodal_reset_password(false)
          tog_login()
        }}
      />
      <SignupModal
        isOpen={modal_signup}
        toggle={() => {
          tog_signup()
          props.showRightSidebarAction(false)
        }}
        onLogin={() => {
          setmodal_signup(false)
          tog_login()
        }}
        onVerify={() => {
          setmodal_signup(false)
          // props.showRightSidebarAction(false)
          tog_signup_verification()
        }}
      />
      <SignupVerificationModal
        isOpen={modal_signup_verification}
        toggle={() => {
          tog_signup_verification()
        }}
        onLogin={() => {
          setmodal_signup_verification(false)
          tog_referral()
          props.showRightSidebarAction(false)
        }}
        onSignup={() => {
          setmodal_signup_verification(false)
          tog_signup()
        }}
      />
      <ReferralModal
        isOpen={modal_referral}
        toggle={() => {
          tog_referral()
        }}
        onSkip={() => {
          setmodal_referral(false)
          props.showRightSidebarAction(true)
          tog_login()
          props.showRightSidebarAction(false)
        }}
        onSubmit={() => {
          setmodal_referral(false)
          props.showRightSidebarAction(true)
          tog_login()
          props.showRightSidebarAction(false)
        }}
      />
      <OnboardModal
        isOpen={modal_onboard}
        toggle={() => {
          tog_onboard()
        }}
        onLogout={() => {
          logout()
        }}
      /> */}
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  logoutUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { ...state.Layout, user }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
  showJoinNowAction,
  showLoginAction,
  logoutUser,
})(RightSidebar)
