import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import gem1 from "../../../assets/images/gems/gem_set_1.svg"
import gem6 from "../../../assets/images/gems/gem_set_6.svg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("")
  const [usernameL, setusernameL] = useState("")
  const [avatar, setavatar] = useState("")
  const [allowanceBalance, setAllowanceBalance] = useState(0)
  const [earningBalance, setEarningBalance] = useState(0)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.first_name)
        setusernameL(obj.last_name)
        setavatar(obj.avatar)
        setAllowanceBalance(obj.allowance_balance)
        setEarningBalance(obj.earning_balance)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <div className="align-self-center me-3">
        <img
          className="rounded-circle header-profile-user me-2"
          src={"https://g5.beuniq.my/storage/gem6.png"}
          alt="Gem icon"
        />
        {earningBalance}
      </div>
      <div className="align-self-center me-3">
        <img
          className="rounded-circle header-profile-user me-2"
          src={"https://g5.beuniq.my/storage/gem1.png"}
          alt="Gem icon"
        />
        {allowanceBalance}
      </div>
      <div className="align-self-center">
        {avatar ? (
          <img
            className="rounded-circle header-profile-user"
            src={avatar}
            alt="Header Avatar"
          />
        ) : (
          <div className="avatar-xs">
            <span
              className={
                "avatar-title rounded-circle bg-primary text-white font-size-16"
              }
            >
              {username.charAt(0) + usernameL.charAt(0)}
            </span>
          </div>
        )}
      </div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <span className="d-none d-xl-inline-block me-1">{username}</span> */}
          <span className="d-inline-block me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* dropdown menu item
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider"/> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
