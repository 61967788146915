import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Modal,
  Input,
} from "reactstrap"
import images from "assets/images"

import { connect } from "react-redux"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import * as country from "helpers/country_list"

import { loginUser } from "store/actions"

import { BigHead } from "@bigheads/core"

const OnboardModal = props => {
  function handleValidSubmit(event, values) {}

  const [displayName, setdisplay_name] = useState("")
  const [user, setUser] = useState(null)
  const [bigHead, setBigHead] = useState({})

  useEffect(() => {
    if (props.user) {
      setUser(JSON.parse(props.user))
      if (user) setdisplay_name(user.display_name)
    }
  }, [props.user])

  useEffect(() => {
    if (user) {
      console.log("in", user)
      setdisplay_name(user.display_name)
      generateBigHead()
    }
  }, [user])

  useEffect(() => {
    generateBigHead()
  }, [])

  const [dob, setDOB] = useState("1970-01-01")
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const changeHandler = e => {
    console.log("change handler", e.target.files[0])
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)

    var reader = new FileReader()
    reader.onload = function (e) {
      document.getElementById("imgPreview").src = e.target.result
    }
    reader.readAsDataURL(e.target.files[0])
  }

  function handleClick(e) {
    document.getElementById("inputProfilePhoto").click()
  }

  const generateBigHead = () => {
    var accessory = ["none", "roundGlasses", "tinyGlasses", "shades"]
    var body = ["chest", "breasts"]
    var circleColor = "blue"
    var clothing = ["naked", "shirt", "dressShirt", "vneck", "tankTop", "dress"]
    var clothingColor = ["white", "blue", "black", "green", "red"]
    var eyebrows = ["raised", "leftLowered", "serious", "angry", "concerned"]
    var eyes = [
      "normal",
      "leftTwitch",
      "happy",
      "content",
      "squint",
      "simple",
      "dizzy",
      "wink",
      "heart",
    ]
    var facialHair = ["none", "none2", "none3", "stubble", "mediumBeard"]
    var graphic = ["none", "redwood", "gatsby", "vue", "react", "graphQL"]
    var hair = [
      "none",
      "long",
      "bun",
      "short",
      "pixie",
      "balding",
      "buzz",
      "afro",
      "bob",
    ]
    var hairColor = [
      "blonde",
      "orange",
      "black",
      "white",
      "brown",
      "blue",
      "pink",
    ]
    var hat = ["none", "none2", "none3", "none4", "none5", "beanie", "turban"]
    var hatColor = ["white", "blue", "black", "green", "red"]
    var lashes = [true, false]
    var lipColor = ["red", "purple", "pink", "turqoise", "green"]
    var mask = false
    var faceMask = false
    var mouth = [
      "grin",
      "sad",
      "openSmile",
      "lips",
      "open",
      "serious",
      "tongue",
    ]
    var skinTone = ["light", "yellow", "brown", "dark", "red", "black"]
    setBigHead({
      accessory: accessory[Math.floor(Math.random() * accessory.length)],
      body: body[Math.floor(Math.random() * body.length)],
      circleColor: circleColor,
      clothing: clothing[Math.floor(Math.random() * clothing.length)],
      clothingColor:
        clothingColor[Math.floor(Math.random() * clothingColor.length)],
      eyebrows: eyebrows[Math.floor(Math.random() * eyebrows.length)],
      eyes: eyes[Math.floor(Math.random() * eyes.length)],
      facialHair: facialHair[Math.floor(Math.random() * facialHair.length)],
      graphic: graphic[Math.floor(Math.random() * graphic.length)],
      hair: hair[Math.floor(Math.random() * hair.length)],
      hairColor: hairColor[Math.floor(Math.random() * hairColor.length)],
      hat: hat[Math.floor(Math.random() * hat.length)],
      hatColor: hatColor[Math.floor(Math.random() * hatColor.length)],
      lashes: lashes[Math.floor(Math.random() * lashes.length)],
      lipColor: lipColor[Math.floor(Math.random() * lipColor.length)],
      mask: mask,
      faceMask: faceMask,
      mouth: mouth[Math.floor(Math.random() * mouth.length)],
      skinTone: skinTone[Math.floor(Math.random() * skinTone.length)],
    })
  }

  function handleValidSubmit(event, values) {
    console.log(values)
    sendOnboard(values)
  }

  const sendOnboard = async values => {
    let formdata = new FormData()
    formdata.append("id", user.id)
    // formdata.append("dob", dob)
    // formdata.append("gender", values.gender)
    // formdata.append("unit", values.display_unit)
    // formdata.append("name", values.name)
    formdata.append("nationality", values.nationality)
    // if (isFilePicked) {
    //   formdata.append("file", selectedFile, "profile.png")
    // } else {
    //   formdata.append("avatar", JSON.stringify(bigHead))
    // }

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_ONBOARD, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      props.toggle()
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-fullscreen"
        id="staticBackdrop"
        backdrop={"static"}
        style={{ backgroundColor: "#FBFBFB" }}
      >
        {/* <div
          className="modal-header"
          style={{ border: "none", backgroundColor: "#FBFBFB" }}
        >
          <button
            type="button"
            onClick={props.toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        <div className="modal-body" style={{ backgroundColor: "#FBFBFB" }}>
          <Row className="justify-content-center">
            <Col md={6} lg={4}>
              <Row className="justify-content-center mb-3">
                <Col xs="auto">
                  <span className="font-weight-black font-size-24 text-primary">
                    {"Let's set you up"}
                  </span>
                </Col>
              </Row>
              <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                {/* <Row>
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Hapivity Name*"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-weight-normal font-size-14 color-62697E">
                      <em>
                        {
                          "Your Hapivity Name is public and always visible to others"
                        }
                      </em>
                    </span>
                  </Col>
                </Row> */}
                {/* <Row className="justify-content-center">
                  <AvField
                    name="name"
                    // label="Email"
                    value={displayName}
                    className="form-control font-weight-semibold font-size-16 mb-3"
                    placeholder="Display Name"
                    type="text"
                    // required
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter display name",
                      },
                    }}
                    onChange={object => {
                      setdisplay_name(object.target.value)
                    }}
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "16px",
                      height: "56px",
                    }}
                  />
                </Row> */}
                {/* <Row>
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Avatar"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-weight-normal font-size-14 color-62697E">
                      <em>
                        {
                          "Everyone can see your avatar next to your Hapivity name in places like maps and profile. Change it as often as you want"
                        }
                      </em>
                    </span>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col>
                    <Card className="border-radius-16 p-3">
                      <Row>
                        <Col xs="auto">
                          {bigHead && !isFilePicked && (
                            <BigHead
                              accessory={bigHead.accessory}
                              body={bigHead.body}
                              circleColor={bigHead.circleColor}
                              clothing={bigHead.clothing}
                              clothingColor={bigHead.clothingColor}
                              eyebrows={bigHead.eyebrows}
                              eyes={bigHead.eyes}
                              facialHair={bigHead.facialHair}
                              graphic={bigHead.graphic}
                              hair={bigHead.hair}
                              hairColor={bigHead.hairColor}
                              hat={bigHead.hat}
                              hatColor={bigHead.hatColor}
                              lashes={bigHead.lashes}
                              lipColor={bigHead.lipColor}
                              mask={bigHead.mask}
                              faceMask={bigHead.faceMask}
                              mouth={bigHead.mouth}
                              skinTone={bigHead.skinTone}
                              style={{
                                width: "80px",
                                height: "80px",
                                backgroundColor: "#ADD8E6",
                                borderRadius: "16px",
                              }}
                            />
                          )}
                          {isFilePicked && (
                            <img
                              // className="rounded-circle avatar-lg"
                              id="imgPreview"
                              // src={selectedFileSrc}
                              alt=""
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "16px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </Col>
                        <Col>
                          <span className="font-weight-black font-size-18 text-secondary">
                            {displayName}
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row> */}
                {/* <Row className="justify-content-center mb-3">
                  <Col>
                    <button
                      className="btn btn-outline-primary btn-block waves-effect waves-light w-100 font-size-16 bg-white text-primary"
                      style={{
                        borderRadius: "16px",
                        height: "56px",
                        fontWeight: 700,
                      }}
                      onClick={e => {
                        e.preventDefault()
                        generateBigHead()
                        setIsFilePicked(false)
                        setSelectedFile(null)
                      }}
                    >
                      {"Generate"}
                    </button>
                  </Col>
                  <Col>
                    <button
                      className="btn btn-light btn-block waves-effect waves-light w-100 font-size-16"
                      style={{
                        borderRadius: "16px",
                        height: "56px",
                        fontWeight: 700,
                      }}
                      onClick={e => {
                        e.preventDefault()
                        handleClick(e)
                      }}
                    >
                      {"Upload"}
                    </button>
                    <Input
                      type="file"
                      className="form-control"
                      id="inputProfilePhoto"
                      accept="image/*"
                      // aria-describedby="inputGroupFileAddon04"
                      // aria-label="Upload"
                      onChange={changeHandler}
                      hidden
                    />
                  </Col>
                </Row> */}
                {/* <Row className="mb-2">
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Date of birth*"}
                    </span>
                  </Col>
                </Row> */}
                {/* <Row className="mb-3">
                  <Col>
                    <input
                      className="form-control font-size-16"
                      type="date"
                      defaultValue="1970-01-01"
                      id="dob"
                      name="dob"
                      style={{
                        height: "56px",
                        borderRadius: "16px",
                        borderWidth: "2px",
                      }}
                      onChange={e => {
                        // console.log(e.target.value)
                        setDOB(e.target.value)
                      }}
                    />
                  </Col>
                </Row> */}
                {/* <Row className="mb-2">
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Gender*"}
                    </span>
                  </Col>
                </Row> */}
                {/* <AvRadioGroup inline name="gender" required>
                  <Row>
                    <Col>
                      <Card
                        style={{
                          height: "56px",
                          borderRadius: "16px",
                          border: "2px solid",
                          borderColor: "#ced4da",
                          borderWidth: "2px",
                        }}
                        className="align-middle shadow-none p-3"
                      >
                        <Row>
                          <Col>
                            <AvRadio
                              // label="Male"
                              value="Male"
                              className="font-size-16 me-0"
                            />
                            <label
                              for="radio-gender-Male"
                              class="form-check-label font-weight-bold font-size-16"
                            >
                              Male
                            </label>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        style={{
                          height: "56px",
                          borderRadius: "16px",
                          border: "2px solid",
                          borderColor: "#ced4da",
                          borderWidth: "2px",
                        }}
                        className="align-middle shadow-none p-3"
                      >
                        <Row>
                          <Col>
                            <AvRadio
                              // label="Female"
                              value="Female"
                              className="font-size-16"
                            />
                            <label
                              for="radio-gender-Female"
                              class="form-check-label font-weight-bold font-size-16"
                            >
                              Female
                            </label>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </AvRadioGroup> */}
                {/* <Row className="mb-2">
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Display Unit*"}
                    </span>
                  </Col>
                </Row> */}
                {/* <AvRadioGroup inline name="display_unit" required>
                  <Row>
                    <Col>
                      <Card
                        style={{
                          height: "56px",
                          borderRadius: "16px",
                          border: "2px solid",
                          borderColor: "#ced4da",
                          borderWidth: "2px",
                        }}
                        className="align-middle shadow-none p-3"
                      >
                        <Row>
                          <Col>
                            <AvRadio
                              // label="Male"
                              value="KM"
                              className="font-size-16 me-0"
                            />
                            <label
                              for="radio-display_unit-KM"
                              class="form-check-label font-weight-bold font-size-16"
                            >
                              KM
                            </label>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        style={{
                          height: "56px",
                          borderRadius: "16px",
                          border: "2px solid",
                          borderColor: "#ced4da",
                          borderWidth: "2px",
                        }}
                        className="align-middle shadow-none p-3"
                      >
                        <Row>
                          <Col>
                            <AvRadio
                              // label="Female"
                              value="Miles"
                              className="font-size-16"
                            />
                            <label
                              for="radio-display_unit-Miles"
                              class="form-check-label font-weight-bold font-size-16"
                            >
                              Miles
                            </label>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </AvRadioGroup> */}
                <Row>
                  <Col>
                    <span className="font-weight-bold font-size-16 text-secondary">
                      {"Nationality*"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-weight-normal font-size-14 color-62697E">
                      <i className="mdi mdi-lock font-size-14 align-middle me-1" />
                      <em>
                        {"You cannot change your Nationality after this."}
                      </em>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      name="nationality"
                      value={"my"}
                      className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-ced4da"
                      // placeholder="Country *"
                      type="select"
                      // validate={{
                      //   required: {
                      //     value: true,
                      //     errorMessage: "This field is required",
                      //   },
                      // }}
                      onChange={e => {
                        // setselected_country(e.target.value)
                      }}
                    >
                      {/* {country.COUNTRY_LIST.map((object, index) => (
                        <option value={object.code}>{object.country}</option>
                      ))} */}
                      <option value="au">Australia</option>
                      <option value="id">Indonesia</option>
                      <option value="my">Malaysia</option>
                      <option value="sg">Singapore</option>
                    </AvField>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col className="w-100">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light w-100 font-size-16"
                      type="submit"
                      style={{
                        borderRadius: "16px",
                        height: "56px",
                        fontWeight: 700,
                      }}
                    >
                      {"Let's go"}
                    </button>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user, error } = state.Login
  return { user, error }
}

export default withRouter(connect(mapStateToProps, { loginUser })(OnboardModal))

OnboardModal.propTypes = {
  setting: PropTypes.object,
  loginUser: PropTypes.func,
  history: PropTypes.object,
}

// export default LoginModal
